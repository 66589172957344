import React, { Fragment, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DG from '../../../assets/images/about/DG2.jpg';
import Porlor from '../../../assets/images/about/porlor2.jpg';

function OurHistory() {
  const [step, setStep] = useState(0);
  const images = [DG, Porlor];

  return (
    <Fragment>
      <div className="container md:pl-20 pl-5 mb-10">
        <h1 className="sm:text-3xl text-2xl font-medium title-font text-black mb-2">Our History</h1>
        <div className="h-1 w-10 bg-blue-800 rounded mb-4"></div>
      </div>
      <div className="flex justify-center mb-10">
        <Carousel
          selectedItem={step}
          showThumbs={false}
          infiniteLoop={true}
          onChange={(e) => {
            setStep(e);
          }}
        >
          {images.map((img, i) => (
            <div className="w-full flex-shrink-0 border-b" key={img}>
              <img src={img} className="w-fullobject-contain  h-full" alt={'Our-History' + i} />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="w-full py-6">
        <div className="flex justify-center">
          {images.map((e, i) => (
            <div className="w-1/4" key={'step' + i}>
              <div className="relative mb-2">
                <div className="absolute flex align-center items-center align-middle content-center" style={{ width: 'calc(100% - 2.5rem - 1rem)', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  <div className={i === 0 ? 'hidden' : 'w-full bg-gray-200 rounded items-center align-middle align-center flex-1'}>
                    <div className="w-0 bg-blue-700 py-1 rounded" style={i <= step ? { width: '100%' } : { width: '0%' }}></div>
                  </div>
                </div>
                <div className={'w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center ' + (i <= step ? 'bg-blue-700' : 'bg-gray-300')}>
                  <span
                    className="text-center text-white w-full cursor-pointer"
                    onClick={(e) => {
                      setStep(i);
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="text-xs text-center md:text-base">{2020 + i}</div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default OurHistory;
