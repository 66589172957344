import Instance from "../helper/Axios";

export async function SaveContact(data) {
  try {
    const response = await Instance.post("Contact/SaveContact", data);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function GetContact(pageSize, currentPage, category, date) {
  try {
    const response = await Instance.get(
      "Contact/GetContact?pageSize=" +
        pageSize +
        "&currentPage=" +
        currentPage +
        "&category=" +
        category +
        "&date=" +
        date
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
