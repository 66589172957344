import React, { Component, Fragment } from "react";
import Input from "../../../components/Input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "../../../components/Select";
import { Formik, Form } from "formik";
import Schema from "./ValidateContact";
import { SaveContact } from "../../../services/Service.Contact";
import Swal from "sweetalert2";
import { getIn, ErrorMessage } from "formik";

class FormContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async Save(data) {
    this.setState({ loading: true });
    const res = await SaveContact(data);
    if (res !== undefined) {
      if (res.statusCode === 1) {
        Swal.fire({
          icon: "success",
          title: "ส่งข้อความสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ส่งข้อความไม่สำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    this.setState({ loading: false });
  }
  render() {
    return (
      <Fragment>
        {/**  <div className="mt-5 mb-5 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800 border-t-4 border-fuchsia-600 border-blue-800 pb-1"> */}
        <div className="pb-1">
          <div className="mx-auto px-4">
            <div className="mr-5 ml-5 mb-5 text-center">
              <label className="text-blue-800 text-3xl text-center">
                ส่งข้อความถึงเรา
              </label>
            </div>
            <Formik
              validationSchema={Schema}
              initialValues={{
                fullname: "",
                email: "",
                phone: "",
                contactType: "",
                msg: "",
              }}
              enableReinitialize={true}
              onSubmit={(value, { resetForm }) => {
                let data = {
                  Fullname: value.fullname,
                  Email: value.email,
                  MobilePhone: value.phone,
                  ContactType: value.contactType,
                  Detail: value.msg,
                };
                this.Save(data);
                resetForm();
              }}
            >
              {({ errors, touched, handleBlur, setFieldValue, values }) => (
                <Form>
                  <div className="w-full">
                    <div className="flex flex-wrap mb-2">
                      <div className="w-full md:w-1/4">
                        <label className="block md:text-right mb-1 md:mb-0 pr-4">
                          ชื่อ-นามสกุล
                        </label>
                      </div>
                      <div className="w-full md:w-3/4">
                        <Input
                          value={values.fullname}
                          errors={errors}
                          touched={touched}
                          name="fullname"
                          type="text"
                          onChange={(value) => {
                            setFieldValue("fullname", value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <div className="w-full md:w-1/4">
                        <label className="block md:text-right mb-1 md:mb-0 pr-4">
                          อีเมล
                        </label>
                      </div>
                      <div className="w-full md:w-3/4">
                        <Input
                          value={values.email}
                          errors={errors}
                          touched={touched}
                          name="email"
                          type="text"
                          onChange={(value) => {
                            setFieldValue("email", value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <div className="w-full md:w-1/4">
                        <label className="block md:text-right mb-1 md:mb-0 pr-4">
                          เบอร์โทรศัพท์มือถือ
                        </label>
                      </div>
                      <div className="w-full md:w-3/4">
                        <Input
                          value={values.phone}
                          errors={errors}
                          touched={touched}
                          name="phone"
                          type="text"
                          onChange={(value) => {
                            setFieldValue("phone", value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <div className="w-full md:w-1/4">
                        <label className="block md:text-right mb-1 md:mb-0 pr-4">
                          ประเภทการติดต่อ
                        </label>
                      </div>
                      <div className="w-full md:w-3/4">
                        <Select
                          value={values.contactType}
                          errors={errors}
                          touched={touched}
                          name="contactType"
                          type="text"
                          onChange={(value) => {
                            setFieldValue("contactType", value);
                          }}
                          text="-- ประเภทการติดต่อ --"
                          data={[
                            { code: "1", name: "สั่งซื้อโปรแกรม" },
                            { code: "2", name: "สอบถามรายละเอียดโปรแกรม" },
                            { code: "3", name: "ขอทดลองใช้โปรแกรม (Demo)" },
                            {
                              code: "4",
                              name: "แจ้งปัญหา/อุปสรรคในการใช้งานโปรแกรม",
                            },
                            {
                              code: "5",
                              name: "เสนอแนะ/แสดงความคิดเห็นเกี่ยวกับโปรแกรม",
                            },
                            { code: "6", name: "อื่นๆ" },
                          ]}
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <div className="w-full md:w-1/4">
                        <label className="block md:text-right mb-1 md:mb-0 pr-4">
                          ข้อความ
                        </label>
                      </div>
                      <div className="w-full md:w-3/4">
                        <div
                          className={
                            getIn(touched, "msg")
                              ? getIn(errors, "msg")
                                ? "bg-white rounded border border-red-500 text-base outline-none text-gray-700"
                                : "bg-white rounded border border-green-500 text-base outline-none text-gray-700"
                              : "bg-white rounded text-base outline-none text-gray-700"
                          }
                        >
                          <CKEditor
                            helperText={touched.msg && errors.msg}
                            name="msg"
                            editor={ClassicEditor}
                            data={values.msg}
                            onChange={(event, editor) => {
                              setFieldValue("msg", editor.getData());
                            }}
                            config={{
                              toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "|",
                                "undo",
                                "redo",
                              ],
                              minHeight: "500px",
                            }}
                          />
                        </div>
                        <div className="text-red-500 text-sm">
                          <ErrorMessage
                            component="div"
                            name="msg"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center mb-10">
                      <div className="flex-1">
                        <button
                          type="submit"
                          disabled={this.state.loading}
                          className={
                            this.state.loading
                              ? "cursor-wait rounded-full flex mx-auto text-white bg-blue-700 border-0 py-2 px-8 focus:outline-none text-lg"
                              : "rounded-full flex mx-auto text-white bg-blue-700 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 text-lg"
                          }
                        >
                          ส่งข้อความ
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FormContact;
