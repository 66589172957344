import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { GetNewsDetailAdmin } from "../../../services/Service.News";
import { Carousel } from "react-responsive-carousel";
function Mgnewsdetails() {
  const history = useHistory();
  const param = useLocation();
  const [data, setData] = useState({
    code: "",
    headlines: "",
    newsDetails: "",
    createDate: "",
    postedBy: "",
    images: [],
  });

  useEffect(() => {
    if (param.state !== undefined) {
      showData(param.state.value.code);
    } else {
      history.push("News");
    }
  }, [history, param.state]);

  async function showData(code) {
    const res = await GetNewsDetailAdmin(code);
    if (res !== undefined) setData(res);
  }

  return (
    <Fragment>
      <div className="container mx-auto mt-10 mb-20">
        <div className="mr-3">
          <div className="mb-5 mr-3">
            <div className="container flex justify-end mx-auto mt-8">
              <span
                className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                onClick={() => {
                  history.push("MGHome");
                }}
              >
                หน้าแรก/
              </span>
              <span
                className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                onClick={() => {
                  history.push("MGNews");
                }}
              >
                จัดการข่าวสาร/
              </span>
              <span className="text-blue-700">รายละเอียดข่าวสาร</span>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-10 ml-5 mr-5 text-center">
          <label className="text-3xl text-blue-800">รายละเอียดข่าวสาร/กิจกรรม</label>
        </div>
        <div className="flex flex-wrap mt-5">
          <div className="pl-4 pr-4 md:w-full">
            <div className="flex flex-wrap">
              <div className="h-3/6 m-11">
                <Carousel showThumbs={true} autoPlay={true} infiniteLoop={true}>
                  {data.images.map((item, ind) => (
                    <img
                      className="object-fill w-full h-5/6"
                      src={item.imgNewsUrl}
                      alt={item.imgNewsUrl}
                      key={ind}
                    />
                  ))}
                </Carousel>
              </div>
            </div>

            <h1 className="mt-10 text-lg text-black lg:text-3xl">{data.headlines}</h1>
            <div className="flex mt-5 text-sm">
              <span>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-3 text-blue-800 lg:w-6 lg:h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="mr-3 text-black">{data.createDate}</span>
                </div>
              </span>
              <span> | </span>
              <span className="ml-3 text-black">
                Posted by:
                <label className="text-blue-700">{data.postedBy}</label>
              </span>
            </div>
            <div className="mt-10">
              <p className="mb-10" dangerouslySetInnerHTML={{ __html: data.newsDetails }} />
              <div className="w-full h-0.5 bg-blue-800 mb-10"></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Mgnewsdetails;
