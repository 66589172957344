import Instance from "../helper/Axios";

export async function GetCareer() {
  try {
    const response = await Instance.get("Career/GetCareer");
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function GetCareerDetail(code) {
  try {
    const response = await Instance.get("Career/GetCareerDetail?id=" + code);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function SaveApplyJob(data) {
  try {
    const formData = new FormData();
    formData.append("Fullname", data.Fullname);
    formData.append("Email", data.Email);
    formData.append("MobilePhone", data.MobilePhone);
    formData.append("AboutYou", data.AboutYou);
    formData.append("CareerCode", data.CareerCode);
    for (let i = 0; i < data.Resume.length; i++) {
      formData.append("Resume", data.Resume[i].file);
    }
    for (let i = 0; i < data.Attach.length; i++) {
      formData.append("Attach", data.Attach[i].url);
    }
    for (let i = 0; i < data.Profile.length; i++) {
      formData.append("Profile", data.Profile[i].file);
    }

    const response = await Instance.post("Career/SaveApplyJob", formData);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
