import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GetCareer } from "../../../services/Service.Career";
import CoverCareer from "../../../assets/images/career/CoverCareer.jpg";
import LoadingJobs from "./LoadingJobs";

export default function ShowJobs() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notData, setNotData] = useState(false);

  useEffect(() => {
    setNotData(false);
    showData();
  }, []);

  async function showData() {
    setData([]);
    setNotData(false);
    setLoading(true);
    const res = await GetCareer();
    if (res !== undefined) {
      if (res.length > 0) {
        setData(res);
      } else {
        setNotData(true);
      }
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <div className="mb-10 mx-auto pb-1">
        <div className="mb-10 text-center">
          <label className="text-blue-800 text-3xl">ร่วมงานกับฮักโค้ด</label>
        </div>
        <section className="text-white body-font">
          <div
            className="bg-cover md:pb-20 pb-20 bg-scroll"
            style={{
              paddingBottom: "10rem",
              backgroundImage: "url(" + CoverCareer + ")",
            }}
          >
            <div className="container flex justify-end flex-col items-center h-full px-5 mx-auto lg:flex-row ">
              <div className="mt-10 lg:w-2/5 lg:pl-12 md:pl-12 p-6 bg-gray-500 rounded-xl bg-opacity-75">
                <h1 className="mb-4 font-medium text-white text-center">
                  "ฮักโค้ดทำงานเป็นทีม ความสำเร็จของงาน คือ ความสำเร็จของทีม"
                </h1>
                <p className="leading-relaxed text-white">
                  ที่นี่ให้ความสำคัญและตระหนักถึงคุณค่าของทีมงานทุกคน
                  เราบริหารทีมแบบครอบครัว ดูแลเอาใจใส่ ให้พนักงานทุกคนมีความสุข
                  เมื่อทุกคนมีความสุขแล้ว
                  ความสุขนั้นจะสะท้อนไปที่ผลลัพธ์ของงานที่จะส่งมอบให้กับลูกค้า
                </p>
                <br />
                <h1 className="mb-4 font-medium text-white text-center ">
                  มาเติบโตไปพร้อมๆ กันกับเราค่ะ
                </h1>
              </div>
            </div>
          </div>
        </section>
        {/** job position */}
        <div className="container px-5 mx-auto mt-5">
          <div className="flex flex-wrap">
            <div className="lg:w-1/2 px-5 w-full pt-12">
              <div className="flex justify-center">
                {data.map((value, index) => (
                  <div className="w-64 mb-5 pr-5" key={"Jobs" + index}>
                    <div
                      className="cursor-pointer c-card block shadow-lg hover:shadow-xl rounded-lg overflow-hidden"
                      onClick={(e) => {
                        history.push("/JobDetail", { value: value });
                        window.scrollTo(0, 0);
                      }}
                    >
                      <div className="relative pb-5 overflow-hidden">
                        <img
                          className="inset-0 h-full w-full object-cover mx-px"
                          src={value.image}
                          alt={value.image}
                        />
                      </div>
                      <div className="p-5 text-center">
                        <h2 className="mt-2 mb-2 font-bold">
                          {value.positionName}
                        </h2>
                        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-gray-700 ">
                          {value.rate} อัตรา
                        </span>
                      </div>
                      <div className="p-3 text-center text-white bg-blue-700">
                        <span>รายละเอียด</span>
                      </div>
                    </div>
                  </div>
                ))}
                <LoadingJobs loading={loading} />
                <div
                  className={
                    notData ? "text-center mt-5 text-red-500" : "hidden"
                  }
                >
                  ไม่มีตำแหน่งงานที่เปิดรับ
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 px-5 w-full pt-12">
              <div className="mb-10">
                <div className="mb-5">
                  <h1 className="sm:text-2xl text-lg font-medium title-font text-black mb-2">
                    การทำงานสไตล์ฮักโค้ด
                  </h1>
                  <div className="h-0.5 w-5 bg-blue-800 rounded mb-4"></div>
                </div>
                <div className="ml-10">
                  <p>
                    1. การเห็นคุณค่าของทุกคนในทีม
                    ทุกคนสามารถสร้างคุณค่าในงานของตัวเอง
                  </p>
                  <p>
                    2. งานที่ยากไม่ใช่อุปสรรค แต่มันคือ "โอกาส"
                    ที่ดีของทีมที่จะได้ดึงศักยภาพของตนออกมาได้อย่างเต็มที่
                  </p>
                  <p>
                    3. ทำงานแบบ Family ช่วยเหลือซึ่งกันและกัน ไม่รู้สึกโดดเดี่ยว
                    และได้ผลลัพธ์แบบ Professional
                  </p>
                  <p>4. เปิดกว้างรับฟังทุกความคิดเห็น ไม่มีการแบ่งชนชั้น</p>
                  <p>
                    5. ไม่เกิดช่องว่างในการบริหารงาน
                    ที่นี่ผู้บริหารมีความใกล้ชิด ดูแลและเข้าใจปัญหาของงานทีมงาน{" "}
                  </p>
                  <p>
                    6. รอยยิ้มของพนักงานเปรียบเสมือนรอยยิ้มของลูกค้า
                    พนักงานมีความสุขในการทำงาน ผลลัพธ์ของงานก็ออกมาดี
                    ลูกค้ามีความพึงพอใจ
                  </p>
                </div>
              </div>
              <div className="mb-10">
                <div className="mb-5">
                  <h1 className="sm:text-2xl text-lg font-medium title-font text-black mb-2">
                    สวัสดิการ
                  </h1>
                  <div className="h-0.5 w-5 bg-blue-800 rounded mb-4"></div>
                </div>
                <div className="ml-10">
                  <p>1. ประกันสังคม</p>
                  <p>2. ประกันชีวิต</p>
                  <p>3. เงินโบนัสทุกปี</p>
                  <p>4. ปรับเงินเดือนปีละ 2 ครั้ง</p>
                  <p>5. ทำงานจันทร์ - ศุกร์ (เวลาเข้างานยืดหยุ่น)</p>
                  <p>6. ค่าทำงานล่วงเวลา</p>
                  <p>7. เบี้ยขยัน</p>
                  <p>8. กิจกรรม OUTING นอกสถานที่ กิจกรรมสายสัมพันธ์</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
