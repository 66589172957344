import React, { Fragment } from "react";
import FormContact from "./FormContact";
import ContactInformation from "./ContactInformation";
import { useHistory } from "react-router";

export default function Contact() {
  const history = useHistory();
  return (
    <Fragment>
      {/* <div className="w-full h-3 bg-gradient-to-t from-blue-800 to-blue-700"></div> */}
      <div className="container pl-10 pr-10">
        <div className="mx-auto ">
          <div className="mr-3">
            <div className="flex justify-end mx-auto mt-8">
              <span
                className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                onClick={() => {
                  history.push("Home");
                }}
              >
                หน้าแรก/
              </span>
              <span className="text-blue-700">ติดต่อเรา</span>
            </div>
          </div>
          <div className="">
            <div className="flex flex-wrap">
              <div className="lg:w-2/3 px-5 w-full pt-12">
                <FormContact />
              </div>
              <div className="lg:w-1/3 px-5 w-full pt-12">
                <ContactInformation />
              </div>
            </div>
            <div
              className="flex flex-wrap mb-10 mt-5"
              style={{ minHeight: "450px" }}
            >
              <iframe
                width="100%"
                height="450px"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                title="map"
                scrolling="no"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d287.7750468601104!2d100.56237524963834!3d13.96907436014549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e3778de59eda67%3A0xe0416bfcb97d2327!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4ruC4seC4geC5guC4hOC5ieC4lCDguIjguLPguIHguLHguJQgKOC4quC4s-C4meC4seC4geC4h-C4suC4meC5g-C4q-C4jeC5iCk!5e0!3m2!1sth!2sth!4v1632476569388!5m2!1sth!2sth"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
