import React, { Component, Fragment } from "react";
import OurHistory from "./OurHistory";
import Vision from "./Vision";
import Team from "./Team";

class About extends Component {
  constructor(props) {
    super(props);
    this.about = React.createRef();
    this.vision = React.createRef();
    this.ourHistory = React.createRef();
    this.team = React.createRef();
  }

  scroll = (section) => {
    section.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  render() {
    return (
      <Fragment>
        <div className="h-6 -mt-5 bg-blue-800" />
        <div className="h-16 text-center text-white bg-blue-800 ">
          <div className="flex justify-center pt-4">
            <div className="grid grid-cols-2 divide-x divide-white">
              <div>
                <span
                  className="mr-3 cursor-pointer"
                  onClick={() => {
                    this.scroll(this.about);
                  }}
                >
                  รู้จักกับเรา
                </span>
              </div>
              {/* <div>
                <span
                  className="mr-3 cursor-pointer"
                  onClick={() => {
                    this.scroll(this.vision);
                  }}
                >
                  วิสัยทัศน์
                </span>
              </div> */}
              <div>
                <span
                  className="ml-3 cursor-pointer"
                  onClick={() => {
                    this.scroll(this.ourHistory);
                  }}
                >
                  Our History
                </span>
              </div>
              {/*
              <div>
                <span
                  className="ml-3 cursor-pointer"
                  onClick={() => {
                    this.scroll(this.team);
                  }}
                >
                  ทีมงานของเรา
                </span>
              </div>
               */}
            </div>
          </div>
        </div>
        <div className="mr-3">
          <div className="container flex justify-end mx-auto mt-8">
            <span
              className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
              onClick={() => {
                this.props.history.push("Home");
              }}
            >
              หน้าแรก/
            </span>
            <span className="text-blue-700">เกี่ยวกับเรา</span>
          </div>
        </div>
        <div className="mb-10">
          <section className="text-gray-600 body-font" ref={this.about}>
            <div className="container px-5 py-10 mx-auto">
              <div className="text-center mb-15">
                <h1 className="mb-4 text-2xl font-medium text-center text-blue-800 sm:text-3xl title-font">
                  รู้จักกับเรา
                </h1>
                <p className="mx-auto text-base leading-relaxed xl:w-2/4 lg:w-3/4">
                  บริษัท ฮักโค้ด จำกัด
                  ก่อตั้งขึ้นจากการรวมกลุ่มของนักพัฒนาซอฟต์แวร์ที่มีความมุ่งมั่น
                  มีจิตใจรักและชื่นชอบการเขียนโปรแกรม
                  ชอบศึกษาค้นคว้าเทคโนโลยีใหม่ๆ
                  เพื่อสร้างผลิตภัณฑ์ซอฟต์แวร์ที่ดี มีคุณภาพ
                  ได้มาตรฐานให้กับลูกค้า
                  พร้อมทั้งให้คำปรึกษาด้านเทคโนโลยีนวัตกรรมดิจิทัล
                </p>
              </div>
            </div>
          </section>
          <section id="vision" ref={this.vision}>
            <Vision />
          </section>
          <section ref={this.ourHistory}>
            <OurHistory />
          </section>
          {/*
         <section ref={this.team}>
          <Team />
        </section>
          */}
        </div>
      </Fragment>
    );
  }
}

export default About;
