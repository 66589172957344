import React, { Component, Fragment } from 'react';
import NewsCoverPage from '../../../assets/images/coverPage/newS.jpg';
import CNews from '../../../components/CNews';
import { GetNews } from '../../../services/Service.News';
import LoadingNews from '../../../components/LoadingNews';

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      notData: false,
      // notData: true,
    };
  }

  componentDidMount() {
    this.setState({ notData: false });
    this.showData();
  }

  async showData() {
    this.setState({ loading: true });
    const res = await GetNews();
    if (res !== undefined) {
      if (res.length > 0) {
        this.setState({ data: res });
      } else {
        this.setState({ notData: true });
      }
      this.setState({ loading: false });
    }
  }
  render() {
    return (
      <Fragment>
        <section className="-mt-5 text-gray-600 body-font">
          <div className="bg-gray-100 ">
            <img alt="gallery" className="w-screen h-16 lg:h-56 md:h-40 sm:h-20" src={NewsCoverPage} />
          </div>
        </section>
        <section id="new">
          <div className="mt-10 mb-10">
            <div className="container px-5 mx-auto">
              <div className="mb-5 mr-3">
                <div className="container flex justify-end mx-auto mt-8">
                  <span
                    className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                    onClick={() => {
                      this.props.history.push('Home');
                    }}
                  >
                    หน้าแรก/
                  </span>
                  <span className="text-blue-700">ข่าวสาร</span>
                </div>
              </div>
              <div className={this.state.notData ? 'hidden' : ''}>
                <CNews data={this.state.data} />
              </div>
              <LoadingNews loading={this.state.loading} />
              <div className={this.state.notData ? 'text-center mt-5 text-red-500' : 'hidden'}>ยังไม่มีข่าวสาร</div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default News;
