import React, { Fragment, useState, useEffect } from "react";
// import Academy from "../../../assets/images/news/academy.png";
import { useHistory, useLocation } from "react-router";
import { GetNewsDetail } from "../../../services/Service.News";
import { Carousel } from "react-responsive-carousel";
import { nanoid } from "nanoid";

function NewsDetails() {
  const history = useHistory();
  const param = useLocation();
  const [data, setData] = useState({
    code: "",
    headlines: "",
    newsDetails: "",
    createDate: "",
    postedBy: "",
    images: [],
    //newsOther: [],
  });

  useEffect(() => {
    if (param.state !== undefined) {
      showData(param.state.value.code);
    } else {
      history.push("News");
    }
  }, [history, param.state]);

  async function showData(code) {
    const res = await GetNewsDetail(code);
    if (res !== undefined) setData(res);
  }

  return (
    <Fragment>
      {/* <div className="w-full h-3 bg-gradient-to-t from-blue-800 to-blue-700"></div> */}
      <div className="container mx-auto mt-10 mb-20">
        <div className="mr-3">
          <div className="container flex justify-end mx-auto mt-8">
            <span
              className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
              onClick={() => {
                history.push("Home");
              }}
            >
              หน้าแรก/
            </span>
            <span
              className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
              onClick={() => {
                history.push("News");
              }}
            >
              ข่าวสาร/
            </span>
            <span className="text-blue-700">รายละเอียด</span>
          </div>
        </div>
        <div className="flex flex-wrap mt-5">
          {/* รายละเอียดข่าว */}
          <div className="pl-4 pr-4 lg:w-3/4 md:w-full">
            <div className="flex flex-wrap">
              <div className="h-3/6 m-11">
                <Carousel showThumbs={true} autoPlay={true} infiniteLoop={true}>
                  {data.images.map((x) => (
                    <img className="object-fill w-full h-5/6" src={x.imgNewsUrl} alt={x.imags} key={nanoid()} />
                  ))}
                </Carousel>
              </div>
            </div>

            <h1 className="mt-10 text-lg text-black lg:text-3xl">{data.headlines}</h1>
            <div className="flex mt-5 text-sm">
              <span>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-3 text-blue-800 lg:w-6 lg:h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  <span className="mr-3 text-black">{data.createDate}</span>
                </div>
              </span>
              <span> | </span>
              <span className="ml-3 text-black">
                Posted by: <label className="text-blue-700">{data.postedBy}</label>
              </span>
            </div>
            <div className="mt-10">
              <p className="mb-10" dangerouslySetInnerHTML={{ __html: data.newsDetails }} />
              <div className="w-full h-0.5 bg-blue-800 mb-10"></div>
            </div>
          </div>
          {/* ข่าวสารน่าสนใจ */}
          <div className="pl-4 pr-4 lg:w-1/4 md:w-full">
            <div className="mb-5">
              <label className="text-lg text-blue-700">ข่าวสารน่าสนใจ</label>
            </div>
            {/* {data.newsOther.map((value, index) => (
              <div
                className="w-full p-3 mb-5 shadow-md cursor-pointer"
                key={"other" + index}
                onClick={(e) => {
                  showData(value.code);
                  window.scrollTo(0, 0);
                }}
              >
                <img
                  src={value.image}
                  alt={Academy}
                  className="ml-auto mr-auto"
                />
                <p className="mt-3 mb-3">{data.headlines}</p>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default NewsDetails;
