import React, { Fragment } from 'react';

function ShowProduct({ data }) {
  return (
    <Fragment>
      <div className="flex flex-wrap -m-4">
        {data.map((value, index) => (
          <div className="p-4 md:w-1/3" key={'Product' + index}>
            <div className="h-full shadow-md rounded-2xl overflow-hidden border-t">
              <img className="lg:h-48 md:h-36 w-full object-cover object-center" src={value.image} alt={value.image} />
              <div className="p-6 text-center">
                <h1 className="text-lg font-medium text-gray-900 mb-3">{value.productName}</h1>
                <h2 className={value.promotionCode ? 'line-through text-xs title-font font-medium text-gray-400 mb-1' : 'hidden'}>ราคา {value.price} บาท</h2>
                <div className={value.promotionCode ? 'hidden' : 'mb-8'}></div>
                <h2 className="text-sm title-font font-medium text-black mb-1">ราคา {value.promotionCode ? (value.price * (100 - value.discount)) / 100 : value.price} บาท</h2>
              </div>
              <div className="flex items-center flex-wrap">
                <div className="w-1/2">
                  <button type="button" className="block w-full bg-blue-700 hover:bg-blue-800 text-white border border-blue-700 px-3 py-2 rounded-bl-2xl uppercase font-poppins font-medium">
                    ทดลองใช้ฟรี !!
                  </button>
                </div>
                <div className="w-1/2">
                  <button
                    type="button"
                    className="block w-full bg-white hover:bg-blue-800 text-blue-700 hover:text-white border border-blue-700 px-3 py-2 rounded-br-2xl uppercase font-poppins font-medium"
                  >
                    สั่งซื้อ
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default ShowProduct;
