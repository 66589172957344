import React, { Component, Fragment } from "react";
import { GetNewsAdmin } from "../../../services/Service.News";
import ShowData from "./ShowData";

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      notData: false,
    };
  }
  componentDidMount() {
    this.setState({ notData: false });
    this.loadData();
  }

  async loadData() {
    this.setState({ loading: true });
    const res = await GetNewsAdmin();
    if (res !== undefined) {
      if (res.length > 0) {
        this.setState({ data: res });
      } else {
        this.setState({ data: [] });
        this.setState({ notData: true });
      }
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <Fragment>
        <section id="new">
          <div className="mt-10 mb-10">
            <div className="container px-5 mx-auto">
              <div className="mb-5 mr-3">
                <div className="container flex justify-end mx-auto mt-8">
                  <span
                    className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                    onClick={() => {
                      this.props.history.push("MGHome");
                    }}
                  >
                    หน้าแรก/
                  </span>
                  <span className="text-blue-700">จัดการข่าวสาร</span>
                </div>
              </div>
              <div className="mt-5 mb-10 ml-5 mr-5 text-center">
                <label className="text-3xl text-blue-800">จัดการข่าวสาร/กิจกรรม</label>
              </div>
              <div className="container flex justify-end mx-auto">
                <button
                  type="button"
                  className="flex px-3 py-2 text-white bg-blue-700 border-0 rounded-md focus:outline-none hover:bg-blue-800"
                  onClick={(e) => {
                    this.props.history.push("FormNews");
                  }}
                >
                  <div>
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                      <span>เพิ่มข่าวสาร</span>
                    </div>
                  </div>
                </button>
              </div>
              <div>
                <ShowData
                  data={this.state.data}
                  reload={(e) => {
                    if (e) {
                      this.loadData();
                    }
                  }}
                />
              </div>
              <div className={this.state.notData ? "text-center mt-5 text-red-500" : "hidden"}>ยังไม่มีข่าวสาร</div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default News;
