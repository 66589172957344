import React, { Fragment } from "react";
import tpqiLogo from "../../../assets/images/customers/tpqi_logo.png";
import porlorLogo from "../../../assets/images/customers/porlor_logo.png";
import rfeLogo from "../../../assets/images/customers/rfe_logo.jpg";
import jinjiLogo from "../../../assets/images/customers/jinji_logo.png";
import palsmartLogo from "../../../assets/images/customers/palsmart.jpg";

export default function ShowCustomers() {
  let imgName = [
    { img: tpqiLogo, name: "สถาบันคุณวุฒิวิชาชีพ (องค์การมหาชน)" },
    { img: porlorLogo, name: "บริษัท ปอลอ เอ็กซ์เพรส จำกัด" },
    { img: rfeLogo, name: "บริษัท อาร์เอฟอี เซอร์วิส จํากัด" },
    { img: jinjiLogo, name: "บริษัท จิณณ์จิ คอร์ปอเรชั่น จำกัด" },
    { img: palsmartLogo, name: "บริษัท พอล สมาร์ท เปเปอร์บ๊อกซ์ จำกัด" },
  ];
  return (
    <div>
      <Fragment>
        <div className="flex flex-wrap items-left justify-left mb-2">
          {imgName.map((value) => {
            return (
              <div className="w-full lg:w-1/4 md:w-2/4" key={value.img}>
                <div className="flex flex-col items-center justify-center">
                  <div className="relative w-40 h-40">
                    <div className="absolute inset-0 z-0 bg-center bg-cover rounded-full shadow-lg">
                      <img
                        src={value.img}
                        className="p-4 m-auto h-36 w-36"
                        alt={value.img}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <h1 className="mb-2 text-lg font-medium text-black sm:text-lg title-font">
                      {value.name}
                    </h1>
                    <div className="h-0.5 w-5 bg-blue-800 rounded mb-4"></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Fragment>
    </div>
  );
}
