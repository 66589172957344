import React, { Component } from 'react';
import Icon from '../../assets/icons/icons';
import { NavLink } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="body-font text-white bg-blue-800">
          {/* <div className="container px-5 py-10 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
            <div className="flex-grow flex justify-center flex-wrap -mb-10 md:mt-0 md:text-left text-center">
              <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 className="font-black text-lg mb-3">บริษัท ฮักโค้ด จำกัด</h2>
                <nav className="list-none mb-10">
                  <li>
                    <NavLink to="Product" className="nav-link hover:text-gray-800">
                      ผลิตภัณฑ์/บริการ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="Customers" className="nav-link hover:text-gray-800">
                      ลูกค้าของเรา
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="Career" className="nav-link hover:text-gray-800">
                      ร่วมงานกับเรา
                    </NavLink>
                  </li>
                </nav>
              </div>
              <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 className="title-font font-black text-lg mb-3">เกี่ยวกับเรา</h2>
                <nav className="list-none mb-10">
                  <li>
                    ก่อตั้งขึ้นด้วยความมุ่งหวังที่จะให้บริการทางด้านซอฟต์แวร์ (Web Development, Mobile Development) การฝึกอบรม ให้คำปรึกษาในด้านเทคโนโลยี
                    เรามุ่งมั่นพัฒนาซอฟต์แวร์ให้มีคุณภาพดียิ่งขึ้นอย่างไม่หยุดยั้ง เพื่อให้ลูกค้ามีความสบายเมื่อได้ใช้ซอฟต์แวร์จากเรา
                  </li>
                </nav>
              </div>
              <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 className="title-font font-black text-lg mb-3">ติดต่อเรา</h2>
                <nav className="list-none mb-10">
                  <li>
                    <div className="flex mb-1">
                      <Icon icon="mapMarker" viewBox="0 0 16 16" color="#ffff" size={32} className="w-6 h-6 mr-3" />
                      <span className="text-left">48/117 หมู่ที่ 3 ต.บ้านใหม่ อ.เมืองปทุมธานี จ.ปทุมธานี 12000</span>
                    </div>
                  </li>
                  <li>
                    <div className="flex mb-1">
                      <Icon icon="telephone" viewBox="0 0 20 20" color="#ffff" size={32} className="w-6 h-6 mr-3" />
                      <span className="text-left">094 570 9907</span>
                    </div>
                  </li>
                  <li>
                    <div className="flex mb-1">
                      <Icon icon="emailFill" viewBox="0 0 20 20" color="#ffff" size={32} className="w-6 h-6 mr-3" />
                      <span className="text-left">info@hugcode.co.th</span>
                    </div>
                  </li>
                </nav>
              </div>
              <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 className="title-font font-black text-lg mb-3">วันทำการ</h2>
                <nav className="list-none mb-10">
                  <li>
                    <div className="flex">
                      <Icon icon="clockFill" viewBox="0 0 20 20" color="#ffff" size={16} className="w-6 h-6 mr-3" />
                      <span className="text-left">ทุกวันจันทร์-วันศุกร์</span>
                      <br />
                    </div>
                  </li>
                  <li>
                    <div className="flex ml-9">
                      <span className="text-left">เวลา : 08:30 - 17:30 น.</span>
                    </div>
                  </li>
                </nav>
              </div>
            </div>
          </div> */}
          <div className="bg-blue-900 text-white">
            <div className="container mx-auto py-4 px-5">
              <p className="text-wilth text-sm text-center">
                © 2021 All right reserved. Designed by
                <a href="/" rel="HUGCODE" className="text-wilth ml-1">
                  WWW.HUGCODE.CO.TH
                </a>
              </p>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
export default Footer;
