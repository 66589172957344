import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function PublicLayout(props) {
  return (
    <>
      <Header {...props} />
      <div style={{ minHeight: 'calc(100vh - 200px)' }}>{props.children}</div>
      <Footer />
    </>
  );
}
