import React, { Fragment } from "react";
import { useHistory } from "react-router";
import ShowCustomers from "./ShowCustomers";

export default function Customers() {
  const history = useHistory();

  return (
    <div>
      <Fragment>
        {/* <div className="w-full h-3 bg-gradient-to-t from-blue-800 to-blue-700"></div> */}
        <div className="container p-4 mx-auto mb-20">
          <div className="mr-3">
            <div className="container flex justify-end mx-auto mt-8">
              <span
                className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                onClick={() => {
                  history.push("Home");
                }}
              >
                หน้าแรก/
              </span>
              <span className="text-blue-700">ลูกค้าของเรา</span>
            </div>
          </div>
          <div className="mt-5 mb-5">
            <div className="mt-5 mb-10 ml-5 mr-5 text-center">
              <label className="text-3xl text-blue-800">ลูกค้าของเรา</label>
            </div>
            <ShowCustomers />
          </div>
        </div>
      </Fragment>
    </div>
  );
}
