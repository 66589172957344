import React, { Fragment } from "react";
import Icon from "../../../assets/icons/icons";
import SVGLine from "../../../svgs/SVGLine";

export default function ContactInformation() {
  return (
    <Fragment>
      <div className="flex flex-wrap w-full mt-12 mb-20">
        <div className="w-full bg-white rounded-lg shadow-md border-t-4 border-fuchsia-600  mb-5 p-5">
          <div className="">
            <label className="text-1xl">ติดต่อเรา</label>
            <div className="h-1 w-8 bg-blue-800 rounded mb-4"></div>
            <div className="ml-5 mt-2">
              <div className="flex mb-1">
                <Icon
                  icon="mapMarker"
                  viewBox="0 0 20 20"
                  color="#2c5282"
                  size={32}
                  className="w-6 h-6 mr-3"
                />
                <span>
                  48/117 หมู่ที่ 3 ต.บ้านใหม่ อ.เมืองปทุมธานี จ.ปทุมธานี 12000
                </span>
              </div>
              <div className="flex mb-1">
                <Icon
                  icon="telephone"
                  viewBox="0 0 20 20"
                  color="#2c5282"
                  size={32}
                  className="w-6 h-6 mr-3"
                />
                <span>094 570 9907</span>
              </div>
              <div className="flex mb-1">
                <Icon
                  icon="emailFill"
                  viewBox="0 0 20 20"
                  color="#2c5282"
                  size={32}
                  className="w-6 h-6 mr-3"
                />
                <span>info@hugcode.co.th</span>
              </div>
            </div>
          </div>
          <div className="mb-5 mt-5">
            <label className="text-1xl">ช่องทาง Social Media</label>
            <div className="h-1 w-8 bg-blue-800 rounded mb-4"></div>
            <div className="ml-5 mt-2">
              <div className="flex mb-1">
                <Icon
                  icon="facebook"
                  viewBox="0 0 20 20"
                  color="#2c5282"
                  size={32}
                  className="w-6 h-6 mr-3"
                />
                <span>ฮักโค้ด Hugcode</span>
              </div>
              <div className="flex mb-1">
                <SVGLine color="#2c5282" className="w-6 h-6 mr-3 " />
                <span className="ml-4">@hugcode</span>
              </div>
            </div>
          </div>
          <div className="mb-5">
            <label className="text-1xl">วันทำการ</label>
            <div className="h-1 w-8 bg-blue-800 rounded mb-4"></div>
            <div className="ml-5 mt-2">
              <div className="flex">
                <Icon
                  icon="clockFill"
                  viewBox="0 0 20 20"
                  color="#2c5282"
                  size={16}
                  className="w-6 h-6 mr-3"
                />
                <span>ทุกวันจันทร์-วันศุกร์</span>
                <br />
              </div>
              <div className="flex ml-9">
                <span>เวลา : 08:30 - 17:30 น.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
