import React, { Fragment } from 'react';

function Loading({ loading }) {
  return (
    <Fragment>
      <div className="flex flex-wrap -m-4">
        <div className="p-4 md:w-1/3">
          <div className="h-full shadow-md rounded-2xl overflow-hidden animate-pulse">
            <div className=" bg-gray-300 lg:h-48 md:h-36 w-full mb-7"></div>
            <div className="p-1 text-center flex justify-center">
              <p className="leading-relaxed mb-8 w-60 h-4 animate-pulse bg-gray-300"></p>
            </div>
            <div className="p-1 text-center flex justify-center">
              <p className="leading-relaxed mb-8 w-40 h-4 animate-pulse bg-gray-300"></p>
            </div>
            <div className=" bg-gray-300 h-11 w-full"></div>
          </div>
        </div>
        <div className="p-4 md:w-1/3">
          <div className="h-full shadow-md rounded-2xl overflow-hidden animate-pulse">
            <div className=" bg-gray-300 lg:h-48 md:h-36 w-full mb-7"></div>
            <div className="p-1 text-center flex justify-center">
              <p className="leading-relaxed mb-8 w-60 h-4 animate-pulse bg-gray-300"></p>
            </div>
            <div className="p-1 text-center flex justify-center">
              <p className="leading-relaxed mb-8 w-40 h-4 animate-pulse bg-gray-300"></p>
            </div>
            <div className=" bg-gray-300 h-11 w-full"></div>
          </div>
        </div>
        <div className="p-4 md:w-1/3">
          <div className="h-full shadow-md rounded-2xl overflow-hidden animate-pulse">
            <div className=" bg-gray-300 lg:h-48 md:h-36 w-full mb-7"></div>
            <div className="p-1 text-center flex justify-center">
              <p className="leading-relaxed mb-8 w-60 h-4 animate-pulse bg-gray-300"></p>
            </div>
            <div className="p-1 text-center flex justify-center">
              <p className="leading-relaxed mb-8 w-40 h-4 animate-pulse bg-gray-300"></p>
            </div>
            <div className=" bg-gray-300 h-11 w-full"></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Loading;
