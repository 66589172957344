import React, { Fragment } from 'react';
import ShowJobs from './ShowJobs';
import { useHistory } from 'react-router';

export default function Career() {
  const history = useHistory();
  return (
    <Fragment>
      {/* <div className="w-full h-3 bg-gradient-to-t from-blue-800 to-blue-700"></div> */}
      <div className="container p-4 mx-auto">
        <div className="mr-3">
          <div className="container flex justify-end mx-auto mt-8">
            <span
              className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
              onClick={() => {
                history.push('Home');
              }}
            >
              หน้าแรก/
            </span>
            <span className="text-blue-700">ร่วมงานกับฮักโค้ด</span>
          </div>
        </div>
        {/** for display content */}
      </div>
      <ShowJobs />
    </Fragment>
  );
}
