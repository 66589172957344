import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import slide1 from "../../../assets/images/home/slider1_v1.jpg";
import slide2 from "../../../assets/images/home/slider2_v1.jpg";

export default function ImgCarousel() {
  const [step, setStep] = useState(0);
  let imgName = [slide1];

  return (
    <Carousel
      selectedItem={step}
      showThumbs={false}
      infiniteLoop={true}
      onChange={(e) => {
        setStep(e);
      }}
      className="-mt-5"
    >
      {imgName.map((img, i) => (
        <div className="flex-shrink-0 w-full border-b" key={img}>
          <img
            src={img}
            className="h-full w-fullobject-contain"
            alt={"slider-" + i}
          />
        </div>
      ))}
    </Carousel>
  );
}
