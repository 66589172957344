// layout
import PubliceLayout from "../layouts/public/PublicLayout";
import PrivateLayout from "../layouts/private/PublicLayout";
// public
import Home from "../views/public/home/Home";
import About from "../views/public/about/About";
import Contact from "../views/public/contact/Contact";
import Customers from "../views/public/customers/Customers";
import Career from "../views/public/career/Career";
import JobDetail from "../views/public/career/JobDetail";
import News from "../views/public/news/News";
import NewsDetails from "../views/public/news/NewsDetails";
import Product from "../views/public/product/Product";
import Academy from "../views/public/academy/Academy";
// private
import MGHome from "../views/private/home/Home";
import MGContact from "../views/private/contact/Contact";
import MGCareer from "../views/private/career/Career";
import MGNews from "../views/private/news/News";
import FormNews from "../views/private/news/FormNews";
import MGProduct from "../views/private/product/Product";
import MGNewsDetails from "../views/private/news/MGNewsDetails";
// error

const Routes = [
  // public
  { path: "/", name: "Home", component: Home, role: 1, layout: PubliceLayout },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    role: 1,
    layout: PubliceLayout,
  },
  {
    path: "/About",
    name: "About",
    component: About,
    role: 1,
    layout: PubliceLayout,
  },
  {
    path: "/Customers",
    name: "Customers",
    component: Customers,
    role: 1,
    layout: PubliceLayout,
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact,
    role: 1,
    layout: PubliceLayout,
  },
  {
    path: "/Career",
    name: "Career",
    component: Career,
    role: 1,
    layout: PubliceLayout,
  },
  {
    path: "/JobDetail",
    name: "JobDetail",
    component: JobDetail,
    role: 1,
    layout: PubliceLayout,
  },
  {
    path: "/News",
    name: "News",
    component: News,
    role: 1,
    layout: PubliceLayout,
  },
  {
    path: "/NewsDetails",
    name: "NewsDetails",
    component: NewsDetails,
    role: 1,
    layout: PubliceLayout,
  },
  {
    path: "/Product",
    name: "Product",
    component: Product,
    role: 1,
    layout: PubliceLayout,
  },
  {
    path: "/Academy",
    name: "Academy",
    component: Academy,
    role: 1,
    layout: PubliceLayout,
  },
  // private
  {
    path: "/MGHome",
    name: "MGHome",
    component: MGHome,
    role: 2,
    layout: PrivateLayout,
  },
  {
    path: "/MGContact",
    name: "MGContact",
    component: MGContact,
    role: 2,
    layout: PrivateLayout,
  },
  {
    path: "/MGCareer",
    name: "MGCareer",
    component: MGCareer,
    role: 2,
    layout: PrivateLayout,
  },
  {
    path: "/MGNews",
    name: "MGNews",
    component: MGNews,
    role: 2,
    layout: PrivateLayout,
  },
  {
    path: "/FormNews",
    name: "FormNews",
    component: FormNews,
    role: 2,
    layout: PrivateLayout,
  },
  {
    path: "/MGProduct",
    name: "MGProduct",
    component: MGProduct,
    role: 2,
    layout: PrivateLayout,
  },
  {
    path: "/MGNewsDetails",
    name: "MGNewsDetails",
    component: MGNewsDetails,
    role: 2,
    layout: PrivateLayout,
  },
];

export default Routes;
