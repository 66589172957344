import * as Yup from 'yup';

const Schema = Yup.object().shape({
  fullname: Yup.string().required('กรุณาระบุ ชื่อ-นามสกุล'),
  email: Yup.string()
    .required('กรุณาระบุ อีเมล')
    .matches(/^[\w-\.]+@([\w-]{2,}.)+[\w-]{2,4}$/, 'รูปแบบอีเมล์ไม่ถูกต้อง'),
  phone: Yup.string()
    .required('กรุณาระบุ เบอร์โทรศัพท์')
    .matches(/^[0-9]{9,10}$/, 'กรุณาตรวจสอบเบอร์โทรศัพท์อีกครั้ง'),
  aboutYou: Yup.string().required('กรุณาระบุ เกี่ยวกับตัวคุณ'),
  resume: Yup.array().test('is-resume', 'กรุณาอัพโหลด ไฟล์ผลงาน', function (value) {
    const { typeUpload } = this.parent;
    if (typeUpload === '1') {
      if (value.length <= 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }),
  attach: Yup.array().when('typeUpload', (value, schema) => {
    if (value === '1') {
      return schema.notRequired();
    } else {
      return schema.of(
        Yup.object().shape({
          url: Yup.string().required('กรุณาระบุ URL'),
        }),
      );
    }
  }),
  profile: Yup.array().test('is-profile', 'กรุณาอัพโหลด รูปของคุณ', function (value) {
    if (value.length <= 0) {
      return false;
    } else {
      return true;
    }
  }),
});

export default Schema;
