import React, { Fragment, useState, useEffect } from 'react';
import CNews from '../../../components/CNews';
import { GetNews } from '../../../services/Service.News';
import LoadingNews from '../../../components/LoadingNews';

function News() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notData, setNotData] = useState(false);

  useEffect(() => {
    setNotData(false);
    showData();
  }, []);

  async function showData() {
    setData([]);
    setNotData(false);
    setLoading(true);
    const res = await GetNews();
    if (res !== undefined) {
      if (res.length > 0) {
        setData(res);
      } else {
        setNotData(true);
      }
      setLoading(false);
    }
  }
  return (
    <Fragment>
      <div className="mb-10 mt-20">
        <div className="container px-5 mx-auto">
          <div className="mb-10">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-black mb-2">ข่าวสาร/กิจกรรมน่าสนใจ</h1>
            <div className="h-1 w-10 bg-blue-800 rounded mb-4"></div>
          </div>
          <div className={notData ? 'hidden' : ''}>
            <CNews data={data} />
          </div>
          <LoadingNews loading={loading} />
          <div className={notData ? 'text-center mt-5 text-red-500' : 'hidden'}>ยังไม่มีข่าวสาร!!</div>
        </div>
      </div>
    </Fragment>
  );
}

export default News;
