import * as Yup from "yup";

export const SchemaNews = Yup.object().shape({
  headlines: Yup.string().required("กรุณาระบุ หัวข้อเรื่อง"),
  newsDetails: Yup.string().required("กรุณาระบุ รายละเอียด"),
  image: Yup.array()
    .max(10, "สามารเพิ่มได้สุงสุด 5 รูปภาพ")
    .min(1, "กรุณาเพิ่มรูปภาพ"),
  publishDate: Yup.date().required("กรุณาระบุ วันทีเผยแพร่"),
  statusPublish: Yup.string().required("กรุณาระบุ สถานะการเผยแผร่"),
});
