import React, { Fragment } from 'react';

function LoadingJobs({ loading }) {
  return (
    <Fragment>
      <div className={loading ? 'w-64 mb-5' : 'hidden'}>
        <div className="c-card block shadow-lg hover:shadow-xl rounded-lg overflow-hidden">
          <div className="animate-pulse">
            <div className=" bg-gray-300 h-40 w-full"></div>
            <div className="pt-10 pb-12">
              <div className="flex justify-center">
                <p className="leading-relaxed mb-3 w-40 h-3 animate-pulse bg-gray-300"></p>
              </div>
              <div className="flex justify-center mt-3">
                <p className="leading-relaxed rounded-full mb-3 w-16 h-5 animate-pulse bg-gray-300"></p>
              </div>
            </div>
            <div className=" bg-gray-300 h-12 w-full"></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LoadingJobs;
