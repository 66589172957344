import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Input from "../../../components/Input";
import { SchemaNews } from "./ValidateContact";
import { GetNewsDetailAdmin, SaveNews, UpDateNews } from "../../../services/Service.News";
import Icon from "../../../assets/icons/icons";
import { getIn, ErrorMessage } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import { URLLOCAL } from "../../../helper/BaseUrl";
import { useHistory, useLocation } from "react-router";

function FormNews(props) {
  const history = useHistory();
  const param = useLocation();
  const [data, setData] = useState({
    code: "",
    headlines: "",
    newsDetails: "",
    createDate: "",
    postedBy: "",
    statusPublish: "",
    publishDateDefault: "",
    publishDate: "",
    images: [],
    image: [],
  });

  useEffect(() => {
    if (param.state !== undefined) {
      showData(param.state.value.code);
    } else {
      //history.push("News");
    }
  }, [history, param.state]);

  async function showData(code) {
    let res = await GetNewsDetailAdmin(code);
    console.log(res,code)
    let sd = new Date(res.publishDateDefault);
    let dateNow = new Date();
    if (sd.getFullYear() === dateNow.getFullYear() + 543) {
      res.publishDateDefault = sd.getFullYear() + "-" + (sd.getMonth() + 1) + "-" + sd.getDate();
    } else {
      res.publishDateDefault = sd.getFullYear() + 543 + "-" + (sd.getMonth() + 1) + "-" + sd.getDate();
    }
    if (res !== undefined) setData(res);
  }

  const Save = async (values) => {
    let sendValue = { ...values };
    let sd = new Date(sendValue.publishDate);
    let dateNow = new Date();
    if (sd.getFullYear() === dateNow.getFullYear() + 543) {
      sendValue.publishDate = sd.getFullYear() + "-" + (sd.getMonth() + 1) + "-" + sd.getDate();
    } else {
      sendValue.publishDate = sd.getFullYear() - 543 + "-" + (sd.getMonth() + 1) + "-" + sd.getDate();
    }
    if (data.code === "") {
      const result = await SaveNews(sendValue);
      if (result !== undefined) {
        if (result.statusCode === 1) {
          Swal.fire({
            icon: "success",
            title: "สร้างกิจกรรม/ข่าวสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/MGNews");
        } else {
          Swal.fire({
            icon: "error",
            title: "s",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    } else {
      const result = await UpDateNews(sendValue, data.code);
      if (result !== undefined) {
        if (result.statusCode === 1) {
          Swal.fire({
            icon: "success",
            title: "สร้างกิจกรรม/ข่าวสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
          props.history.push("/MGNews");
        } else {
          Swal.fire({
            icon: "error",
            title: "s",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    }
  };

  return (
    <Fragment>
      <section id="new">
        <div className="mt-10 mb-10">
          <div className="container px-5 mx-auto">
            <div className="mb-5 mr-3">
              <div className="container flex justify-end mx-auto mt-8">
                <span
                  className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                  onClick={() => {
                    props.history.push("MGHome");
                  }}
                >
                  หน้าแรก/
                </span>
                <span
                  className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                  onClick={() => {
                    props.history.push("MGNews");
                  }}
                >
                  จัดการข่าวสาร/
                </span>
                <span className="text-blue-700">เพิ่มข่าวสารกิจกรรม</span>
              </div>
            </div>

            <div className="m-8 text-center">
              <label className="text-3xl text-blue-800">เพิ่มข่าวสาร/กิจกรรม</label>
            </div>
            <div>
              <Formik
                validationSchema={SchemaNews}
                initialValues={{
                  headlines: data.headlines !== null ? data.headlines : "",
                  newsDetails: data.newsDetails !== null ? data.newsDetails : "",
                  postedBy: data.postedBy !== null ? data.postedBy : "test test",
                  image: data.images !== null ? data.images : [],
                  publishDate: data.publishDateDefault !== null ? data.publishDateDefault : "",
                  statusPublish: data.statusPublish !== null ? data.statusPublish : "0",
                  removeImg: [],
                  imgOld: data.images,
                }}
                enableReinitialize
                onSubmit={(values) => {
                  Save(values);
                }}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <Form>
                    <div className="w-full mx-8">
                      <div className="flex flex-wrap items-center justify-center my-4">
                        <div className="w-full md:w-3/4">
                          <label className="block text-sm font-medium text-gray-700">หัวข่าว</label>
                          <Input
                            value={values.headlines}
                            errors={errors}
                            touched={touched}
                            name="headlines"
                            type="text"
                            onChange={(value) => {
                              setFieldValue("headlines", value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center justify-center my-4">
                        <div className="w-full md:w-3/4">
                          <label className="block text-sm font-medium text-gray-700">รายละเอียด</label>
                          <div
                            className={
                              getIn(touched, "newsDetails")
                                ? getIn(errors, "newsDetails")
                                  ? "bg-white rounded border border-red-500 text-base outline-none text-gray-700"
                                  : "bg-white rounded border border-green-500 text-base outline-none text-gray-700"
                                : "bg-white rounded text-base outline-none text-gray-700"
                            }
                          >
                            <CKEditor
                              helperText={touched.newsDetails && errors.newsDetails}
                              name="newsDetails"
                              data={values.newsDetails}
                              onChange={(event, editor) => {
                                setFieldValue("newsDetails", editor.getData());
                              }}
                              config={{
                                ckfinder: {
                                  uploadUrl: URLLOCAL + "News/UpFileCKEditorNews",
                                },
                              }}
                              editor={ClassicEditor}
                            />
                          </div>
                          <div className="text-sm text-red-500">
                            <ErrorMessage component="div" name="newsDetails" className="invalid-feedback" />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center justify-center my-4">
                        <div className="grid w-full grid-cols-4 gap-4 md:w-3/4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">สถานะการเผยแผร่</label>
                            <div
                              className={
                                getIn(touched, "statusPublish")
                                  ? getIn(errors, "statusPublish")
                                    ? "flex justify-center py-2 border border-red-400 border-solid rounded"
                                    : "flex justify-center py-2 border border-green-400 border-solid rounded"
                                  : "flex justify-center py-2 border border-gray-400 border-solid rounded"
                              }
                            >
                              <div name="statusPublish">
                                <label className="inline-flex items-center">
                                  <input
                                    value="1"
                                    checked={values.statusPublish === "1" ? true : false}
                                    type="radio"
                                    name="statusPublish"
                                    onChange={(e) => {
                                      setFieldValue("statusPublish", e.target.value);
                                    }}
                                  />
                                  <span className="ml-2">เผยแพร่</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                  <input
                                    value="2"
                                    checked={values.statusPublish === "2" ? true : false}
                                    type="radio"
                                    name="statusPublish"
                                    onChange={(e) => {
                                      console.log(values);
                                      setFieldValue("statusPublish", e.target.value);
                                    }}
                                  />
                                  <span className="ml-2">ไม่เผยแพร่</span>
                                </label>
                              </div>
                            </div>
                            <div className="text-sm text-red-500">
                              <ErrorMessage component="div" name="statusPublish" className="invalid-feedback" />
                            </div>
                          </div>

                          <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700">วันทีเผยแพร่</label>
                            <Input
                              errors={errors}
                              touched={touched}
                              onChange={(value) => {
                                setFieldValue("publishDate", value);
                              }}
                              value={values.publishDate}
                              name="publishDate"
                              type="date"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap items-center justify-center my-4">
                        <div className="w-full md:w-3/4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">รูปภาพ</label>
                            <div
                              className={
                                "cursor-pointer flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md " +
                                (getIn(touched, "image")
                                  ? getIn(errors, "image")
                                    ? "hover:border-red-500 border-red-300 hover:bg-red-100 bg-red-50"
                                    : "hover:border-green-500 border-green-300 hover:bg-green-100 bg-green-50"
                                  : "hover:border-gray-500 border-gray-300 hover:bg-gray-100 bg-gray-50")
                              }
                              onClick={() => {
                                document.getElementById("file-upload").click();
                              }}
                            >
                              <div>
                                {values.image.length !== 0 ? (
                                  <div className="flex flex-wrap">
                                    {values.image.map((img, keyind) => (
                                      <div className="relative filter drop-shadow-lg" key={keyind}>
                                        <div className="w-24 h-full m-2">
                                          <img
                                            src={Object.keys(img).length !== 0 ? img.imgNewsUrl : URL.createObjectURL(img)}
                                            alt=""
                                            width="100%"
                                            height="100%"
                                          />
                                        </div>
                                        <button
                                          className="absolute top-0 right-0 flex justify-center "
                                          type="button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            let img = { ...values };
                                            if (Object.keys(img.image[keyind]).length !== 0) {
                                              img.removeImg.push(img.image[keyind].imgNewsUrl);
                                              setFieldValue("removeImg", img.removeImg);
                                            }
                                            let result = img.image.filter((item, indx) => indx !== keyind);
                                            setFieldValue("image", result);
                                          }}
                                        >
                                          <span className="text-white bg-red-700 bg-opacity-75 rounded-full hover:bg-red-800">
                                            <Icon icon="close" className="w-6 h-6 fill-current" color="#" />
                                          </span>
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <svg
                                    className="w-12 h-12 mx-auto text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}

                                <div className="flex justify-center text-sm text-red-600">
                                  <label className="cursor-pointer">
                                    <span>อัพโหลดรูปภาพ</span>
                                  </label>
                                  <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-center text-gray-500">PNG, JPG, JPEG ขนาดไม่เกิน 10MB</p>
                              </div>
                            </div>

                            <div className="text-sm text-red-500">
                              <ErrorMessage component="div" name="image" className="invalid-feedback" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-center my-4 mb-10">
                        <button
                          type="submit"
                          className="flex px-8 py-2 mx-4 text-lg text-white bg-blue-700 border-0 rounded-full focus:outline-none hover:bg-blue-800"
                        >
                          <Icon icon="save" className="flex-1 w-8 h-8 fill-current " color="#" />
                          บันทึก
                        </button>
                        <button
                          onClick={() => {
                            props.history.push("MGNews");
                          }}
                          type="reset"
                          className="flex px-8 py-2 mx-4 text-lg text-white bg-red-700 border-0 rounded-full focus:outline-none hover:bg-red-800"
                        >
                          <Icon icon="close" className="flex-1 w-8 h-8 fill-current " color="#" />
                          ยกเลิก
                        </button>
                      </div>
                    </div>
                    <input
                      id="file-upload"
                      type="file"
                      className="sr-only"
                      name="image"
                      multiple
                      accept="image/*"
                      onChange={(e) => {
                        let addimg = [...values.image];
                        addimg.push(...e.target.files);
                        setFieldValue("image", addimg);
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default FormNews;
