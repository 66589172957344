import React, { Component, Fragment } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import Input from '../../../components/Input';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Schema from './ValidateApplyJob';
import { SaveApplyJob } from '../../../services/Service.Career';
import Swal from 'sweetalert2';
import { getIn, ErrorMessage } from 'formik';

class FormApplyJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async Save(data) {
    this.setState({ loading: true });
    const res = await SaveApplyJob(data);
    if (res !== undefined) {
      if (res.statusCode === 1) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'บันทึกข้อมูลไม่สำเร็จ',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    this.setState({ loading: false });
  }
  render() {
    return (
      <Fragment>
        <div className="mt-5 mb-5 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800 border-t-4 border-fuchsia-600 border-blue-800 pb-1">
          <div className="mx-auto px-4">
            <div className="mr-5 ml-5 mb-5 mt-5 text-center">
              <label className="text-blue-700 text-3xl">สมัครงานตำแหน่งนี้</label>
            </div>
            <Formik
              validationSchema={Schema}
              initialValues={{
                careerCode: this.props.data.code,
                fullname: '',
                email: '',
                phone: '',
                aboutYou: '',
                typeUpload: '1',
                resume: [],
                attach: [{ url: '' }],
                profile: [],
              }}
              enableReinitialize={true}
              onSubmit={(value, { resetForm }) => {
                if (value.careerCode !== null) {
                  var data = {
                    Fullname: value.fullname,
                    Email: value.email,
                    MobilePhone: value.phone,
                    AboutYou: value.aboutYou,
                    CareerCode: value.careerCode,
                    Resume: value.resume,
                    Attach: value.attach,
                    Profile: value.profile,
                  };
                  this.Save(data);
                  resetForm();
                }
              }}
            >
              {({ errors, touched, handleBlur, setFieldValue, values }) => (
                <Form>
                  <div className="w-full">
                    <div className="container py-12 mx-auto">
                      <div className="flex flex-wrap mb-2">
                        <div className="w-full md:w-1/5">
                          <label className="block md:text-right mb-1 md:mb-0 pr-4">ชื่อ-นามสกุล</label>
                        </div>
                        <div className="w-full md:w-3/5">
                          <Input
                            value={values.fullname}
                            errors={errors}
                            touched={touched}
                            name="fullname"
                            type="text"
                            onChange={(value) => {
                              setFieldValue('fullname', value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap mb-2">
                        <div className="w-full md:w-1/5">
                          <label className="block md:text-right mb-1 md:mb-0 pr-4">อีเมล</label>
                        </div>
                        <div className="w-full md:w-3/5">
                          <Input
                            value={values.email}
                            errors={errors}
                            touched={touched}
                            name="email"
                            type="text"
                            onChange={(value) => {
                              setFieldValue('email', value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap mb-2">
                        <div className="w-full md:w-1/5">
                          <label className="block md:text-right mb-1 md:mb-0 pr-4">เบอร์โทรศัพท์มือถือ</label>
                        </div>
                        <div className="w-full md:w-3/5">
                          <Input
                            value={values.phone}
                            errors={errors}
                            touched={touched}
                            name="phone"
                            type="text"
                            onChange={(value) => {
                              setFieldValue('phone', value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap mb-2">
                        <div className="w-full md:w-1/5">
                          <label className="block md:text-right mb-1 md:mb-0 pr-4">บอกเกี่ยวกับตัวคุณ (เช่น นิสัยใจคอ สิ่งที่เคยทำ สิ่งที่ชอบหรือไม่ชอบ เป็นต้น)</label>
                        </div>
                        <div className="w-full md:w-3/5">
                          <div
                            className={
                              getIn(touched, 'aboutYou')
                                ? getIn(errors, 'aboutYou')
                                  ? 'bg-white rounded border border-red-500 text-base outline-none text-gray-700'
                                  : 'bg-white rounded border border-green-500 text-base outline-none text-gray-700'
                                : 'bg-white rounded text-base outline-none text-gray-700'
                            }
                          >
                            <CKEditor
                              helperText={touched.aboutYou && errors.aboutYou}
                              name="aboutYou"
                              editor={ClassicEditor}
                              data={values.aboutYou}
                              onChange={(event, editor) => {
                                setFieldValue('aboutYou', editor.getData());
                              }}
                              config={{ toolbar: ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo'], minHeight: '500px' }}
                            />
                          </div>
                          <div className="text-red-500 text-sm">
                            <ErrorMessage component="div" name="aboutYou" className="invalid-feedback" />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center lg:justify-start flex-wrap mb-2">
                        <div className="w-full md:w-1/5"></div>
                        <div className="w-full md:w-3/5">
                          <div className="mb-5 mt-10">
                            <h1 className="sm:text-xl text-lg font-medium title-font text-black mb-2">เอกสารเพิ่มเติม</h1>
                            <div className="h-0.5 w-5 bg-blue-800 rounded mb-4"></div>
                          </div>
                          <div className="w-full">
                            <label htmlFor="name" className="leading-7 text-lg">
                              แฟ้มสะสมผลงานของคุณ (Resume)
                            </label>
                            <div className="mt-2">
                              <label className="inline-flex items-center">
                                <input
                                  checked={values.typeUpload === '1' ? true : false}
                                  type="radio"
                                  className="form-radio"
                                  name="accountType"
                                  value="1"
                                  onChange={() => {
                                    setFieldValue('typeUpload', '1');
                                  }}
                                />
                                <span className="ml-2">อัพโหลดไฟล์</span>
                              </label>
                              <label className="inline-flex items-center ml-6">
                                <input
                                  checked={values.typeUpload === '2' ? true : false}
                                  type="radio"
                                  className="form-radio"
                                  name="accountType"
                                  value="2"
                                  onChange={() => {
                                    setFieldValue('typeUpload', '2');
                                  }}
                                />
                                <span className="ml-2">แนบ URL</span>
                              </label>
                            </div>
                            <div className={values.typeUpload === '1' ? 'mt-1' : 'hidden'}>
                              <FieldArray name="resume">
                                {({ push, remove }) => (
                                  <Fragment>
                                    {values.resume.map((value, index) => (
                                      <div key={'resume' + index}>
                                        <div className="flex mt-3">
                                          <div className="p-2 lg:w-3/5 w-4/5">
                                            <div className="w-full h-10 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out bg-white rounded border border-green-500 text-base outline-none text-gray-700">
                                              {value.name_original}
                                            </div>
                                          </div>
                                          <div className="ml-2 m-auto">
                                            <button
                                              type="button"
                                              className="bg-red-600 text-white p-2 rounded-md w-14"
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              ลบ
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="text-center pt-4 pb-2 pr-4 pl-4 mt-5 w-60">
                                      <label
                                        className={
                                          'cursor-pointer rounded-full flex justify-center mx-auto py-1 focus:outline-none text-lg ' +
                                          (touched.resume
                                            ? errors.resume !== undefined
                                              ? 'text-red-500 border-2 border-red-500'
                                              : 'text-blue-700 border-2 border-blue-700'
                                            : 'text-blue-700 border-2 border-blue-700')
                                        }
                                        name="resume"
                                      >
                                        อัพโหลดไฟล์
                                        <input
                                          type="file"
                                          hidden
                                          name="resume"
                                          accept=".png,.jpg,.jpeg,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                          onChange={(e) => {
                                            push({
                                              file: e.currentTarget.files[0],
                                              name_original: e.target.files[0].name,
                                            });
                                          }}
                                        />
                                      </label>
                                      <div className="text-red-500 text-sm mt-3">
                                        {touched.resume ? errors.resume !== undefined ? <div className="text-red-500 text-sm mt-3">{errors.resume}</div> : '' : ''}
                                      </div>
                                    </div>
                                    <small className="text-xs">รองรับไฟล์ *.JPG *.PNG *.DOC *.PDF ขนาดไม่เกิน 2 MB</small>
                                  </Fragment>
                                )}
                              </FieldArray>
                            </div>
                            <div className={values.typeUpload === '2' ? 'mt-1' : 'hidden'}>
                              <FieldArray name="attach">
                                {({ push, remove }) => (
                                  <Fragment>
                                    {values.attach.map((value, index) => (
                                      <div key={'attach' + index}>
                                        <div className="flex mt-3">
                                          <div className="p-2 lg:w-3/5 w-4/5">
                                            <Input
                                              errors={errors}
                                              touched={touched}
                                              value={value.url}
                                              id={`attach[${index}].url`}
                                              name={`attach[${index}].url`}
                                              type="text"
                                              onChange={(value) => {
                                                setFieldValue(`attach[${index}].url`, value);
                                              }}
                                            />
                                          </div>
                                          <div className="ml-2 mt-2">
                                            <button
                                              type="button"
                                              className={index > 0 ? 'bg-red-600 text-white p-2 rounded-md w-14' : 'hidden'}
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              ลบ
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="text-center pt-4 pb-2 pr-4 pl-4 mt-5 w-60">
                                      <button
                                        type="button"
                                        className="cursor-pointer rounded-full flex justify-center mx-auto py-1 focus:outline-none text-lg w-52 text-blue-700 border-2 border-blue-700"
                                        onClick={() => {
                                          push({
                                            url: '',
                                          });
                                        }}
                                      >
                                        เพิ่ม
                                      </button>
                                    </div>
                                    <small className="text-xs">แนบไฟล์เอกสารผลงานด้วย URL</small>
                                  </Fragment>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                          <div className="w-full mt-5">
                            <label htmlFor="name" className="leading-7 text-lg">
                              รูปของคุณ
                            </label>
                            <div className="mt-1">
                              <FieldArray name="profile">
                                {({ push, remove }) => (
                                  <Fragment>
                                    {values.profile.map((value, index) => (
                                      <div key={'profile' + index}>
                                        <div className="flex mt-3">
                                          <div className="p-2 lg:w-3/5 w-4/5">
                                            <div className="w-full h-10 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out bg-white rounded border border-green-500 text-base outline-none text-gray-700">
                                              {value.name_original}
                                            </div>
                                          </div>
                                          <div className="ml-2 m-auto">
                                            <button
                                              type="button"
                                              className="bg-red-600 text-white p-2 rounded-md w-14"
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            >
                                              ลบ
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="text-center pt-4 pb-2 pr-4 pl-4 mt-5 w-60">
                                      <label
                                        className={
                                          'cursor-pointer rounded-full flex justify-center mx-auto py-1 focus:outline-none text-lg ' +
                                          (touched.profile
                                            ? errors.profile !== undefined
                                              ? 'text-red-500 border-2 border-red-500'
                                              : 'text-blue-700 border-2 border-blue-700'
                                            : 'text-blue-700 border-2 border-blue-700')
                                        }
                                        name="profile"
                                      >
                                        อัพโหลดไฟล์
                                        <input
                                          type="file"
                                          hidden
                                          name="profile"
                                          accept=".png,.jpg,.jpeg,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                          onChange={(e) => {
                                            push({
                                              file: e.currentTarget.files[0],
                                              name_original: e.target.files[0].name,
                                            });
                                          }}
                                        />
                                      </label>
                                      <div className="text-red-500 text-sm mt-3">
                                        {touched.profile ? errors.profile !== undefined ? <div className="text-red-500 text-sm mt-3">{errors.profile}</div> : '' : ''}
                                      </div>
                                    </div>
                                    <small className="text-xs">รองรับไฟล์ *.JPG *.PNG *.DOC *.PDF ขนาดไม่เกิน 2 MB</small>
                                  </Fragment>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-2 w-full mt-10">
                        <button
                          type="submit"
                          disabled={this.state.loading}
                          className={
                            this.state.loading
                              ? 'cursor-wait rounded-full flex mx-auto text-white bg-blue-700 border-0 py-2 px-8 focus:outline-none text-lg'
                              : 'rounded-full flex mx-auto text-white bg-blue-700 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 text-lg'
                          }
                        >
                          ส่งใบสมัคร
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FormApplyJob;
