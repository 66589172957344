import React, { Fragment } from "react";
import CCard from "../../../components/CCard";
import Tpqi from "../../../assets/images/logo/tpqi.png";
import Porlor from "../../../assets/images/logo/porlor.png";
import Jinji from "../../../assets/images/logo/jinji.png";

function OurWork() {
  var ourWork = [
    { jobName: "Digital Government (2561)", logo: Tpqi },
    { jobName: "Digital Government (2563)", logo: Tpqi },
    { jobName: "โครงการพัฒนาระบบการเชื่อมโยงประเภทมาตรฐานอาชีพ", logo: Tpqi },
    { jobName: "ระบบบริหารจัดการขนส่ง", logo: Porlor },
    { jobName: "JINJI JOB", logo: Jinji },
  ];
  return (
    <Fragment>
      <div className="mb-10">
      <div className="grid mt-20 mb-5 ml-5 mr-5 text-center justify-items-center">
          <h1 className="text-xl text-blue-800 lg:text-3xl">
            บริษัทของเรามุ่งพัฒนาซอฟต์แวร์โดยคำนึงถึงคุณภาพที่ดี
          </h1>
          <p className="mt-5 lg:text-xl">
            ผสมผสานกับความเรียบง่าย การใช้งานที่ไม่ซับซ้อน
            เพื่อตอบโจทย์การใช้งานของผู้ใช้ทุกระดับชั้น
            ในราคาที่ทุกท่านสามารถเข้าถึงได้
          </p>
          <div className="w-10 h-1 mt-5 mb-4 bg-blue-800 rounded"></div>
        </div>
        <div className="container px-5 mx-auto">
          <div className="mb-10">
            <h1 className="mb-2 text-2xl font-medium text-black sm:text-3xl title-font">
              ผลงานของเรา
            </h1>
            <div className="w-10 h-1 mb-4 bg-blue-800 rounded"></div>
          </div>
          <div className="flex flex-wrap -m-4">
            {ourWork.map((value, index) => (
              <CCard
                logo={value.logo}
                text={value.jobName}
                key={"OurWork" + index}
              />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default OurWork;
