import * as Yup from "yup";

const Schema = Yup.object().shape({
  fullname: Yup.string().required("กรุณาระบุ ชื่อ-นามสกุล"),
  email: Yup.string()
    .required("กรุณาระบุ อีเมล")
    .matches(/^[\w-\.]+@([\w-]{2,}.)+[\w-]{2,4}$/, "รูปแบบอีเมล์ไม่ถูกต้อง"),
  phone: Yup.string()
    .required("กรุณาระบุ เบอร์โทรศัพท์")
    .matches(/^[0-9]{9,10}$/, "กรุณาตรวจสอบเบอร์โทรศัพท์อีกครั้ง"),
  contactType: Yup.string().required("กรุณาระบุ ประเภทการติดต่อ"),
  msg: Yup.string().required("กรุณาระบุ ข้อความถึงเรา"),
});

export default Schema;

export const SchemaNews = Yup.object().shape({
  headlines: Yup.string().required("กรุณาระบุ หัวข้อเรื่อง"),
  newsDetails: Yup.string().required("กรุณาระบุ รายละเอียด"),
  image: Yup.array().max(5, "สามารเพิ่มได้สุงสุด 5 รูป"),
  publishDate: Yup.date().required("กรุณาระบุ วันทีเผยแพร่"),
  statusPublish: Yup.string().required("กรุณาระบุ สถานะการเผยแผร่"),
});
