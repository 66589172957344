import Instance from "../helper/Axios";

export async function GetNews() {
  try {
    const response = await Instance.get("News/GetNews");
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function GetNewsAdmin() {
  try {
    const response = await Instance.get("News/GetNewsAdmin");
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function GetNewsDetail(code) {
  try {
    const response = await Instance.get("News/GetNewsDetail?id=" + code);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function GetNewsDetailAdmin(code) {
  try {
    const response = await Instance.get("News/GetNewsDetailAdmin?id=" + code);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function SaveNews(values) {
  try {
    let formData = new FormData();
    formData.append("Headlines", values.headlines);
    formData.append("NewsDetails", values.newsDetails);
    formData.append("PostedBy", values.postedBy);
    formData.append("StatusPublish", values.statusPublish);
    formData.append("PublishDate", values.publishDate);
    for (let i = 0; i < values.image.length; i++) {
      formData.append("Image", values.image[i]);
    }
    const response = await Instance.post("News/SaveNews", formData);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function DeleteNews(code) {
  try {
    const response = await Instance.put("News/UpIsUse/" + code);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function UpDateNews(values, code) {
  try {
    console.log(values);
    let formData = new FormData();
    formData.append("Headlines", values.headlines);
    formData.append("NewsDetails", values.newsDetails);
    formData.append("PostedBy", values.postedBy);
    formData.append("StatusPublish", values.statusPublish);
    if (values.removeImg.length !== 0) {
      formData.append("RemoveImg", values.removeImg);
    }
    formData.append("PublishDate", values.publishDate);
    for (let i = 0; i < values.image.length; i++) {
      formData.append("Image", values.image[i]);
    }
    const response = await Instance.put("News/UpDateNews/" + code, formData);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
