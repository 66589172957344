import React, { Fragment, useEffect, useState } from "react";
import SVGView from "../../../svgs/SVGView";
import SVGEdit from "../../../svgs/SVGEdit";
import SVGDelete from "../../../svgs/SVGDelete";
import { useHistory } from "react-router";
import { DeleteNews } from "../../../services/Service.News";

import Swal from "sweetalert2";
import { DElALERT } from "../../../helper/alert";
function ShowData({ data, reload }) {
  const history = useHistory();
  const [daily, setDaily] = useState([]);

  useEffect(() => {
    setDaily(data);
  }, [data]);

  const Del = async (code) => {
    let result = await DeleteNews(code);
    if (result.statusCode === 1) {
      Swal.fire({
        icon: "success",
        title: "ลบสำเร็จ",
        showConfirmButton: false,
        timer: 1500,
      });
      reload(true);
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  return (
    <Fragment>
      <div className="overflow-x-auto">
        <div className="flex items-center justify-center overflow-hidden min-w-screen">
          <div className="w-full">
            <div className="my-6 overflow-auto bg-white rounded">
              <table className="w-full table-auto min-w-max ">
                <thead>
                  <tr className="text-sm leading-normal text-white bg-blue-800 percase">
                    <th className="px-6 py-3 text-left">#</th>
                    <th className="px-6 py-3 text-left">หัวข่าว</th>
                    <th className="px-6 py-3 text-left">วันที่เผยแพร่</th>
                    <th className="px-6 py-3 text-center">สถานะการเผยแพร่</th>
                    <th className="px-6 py-3 text-center">จัดการ</th>
                  </tr>
                </thead>
                <tbody className="text-sm font-light text-gray-600">
                  {daily.map((value, index) => (
                    <tr className={"border-b border-gray-200 " + (0 % 2 !== 0 ? "bg-gray-100" : "")} key={value.code}>
                      <td className="px-6 py-3 text-center">
                        <div className="flex items-center">{index + 1}</div>
                      </td>
                      <td className="px-6 py-3 text-left w-96 ">
                        <p className="truncate w-96">{value.headlines}</p>
                      </td>
                      <td className="px-6 py-3 text-left">
                        <div className="flex items-center">
                          <span>{value.publishDate}</span>
                        </div>
                      </td>
                      <td className="px-6 py-3 text-center">
                        <span
                          className={
                            value.statusPublish === "1"
                              ? "bg-green-500 text-white py-1 px-3 rounded-full text-xs cursor-pointer"
                              : "bg-red-600 text-white py-1 px-3 rounded-full text-xs cursor-pointer"
                          }
                        >
                          {value.statusPublish === "1" ? "เผยแพร่" : "ไม่เผยแพร่"}
                        </span>
                      </td>
                      <td className="px-6 py-3 text-center">
                        <div className="flex justify-center item-center">
                          <div
                            className="w-4 transform cursor-pointer hover:text-blue-700 hover:scale-110"
                            onClick={() => {
                              history.push("MGNewsDetails", { value: value });
                            }}
                          >
                            <SVGView color="none" />
                          </div>
                          <div
                            className="w-4 mx-4 transform cursor-pointer hover:text-blue-700 hover:scale-110"
                            onClick={() => {
                              history.push("FormNews", { value: value });
                            }}
                          >
                            <SVGEdit color="none" />
                          </div>
                          <div
                            className="w-4 transform cursor-pointer hover:text-blue-700 hover:scale-110"
                            onClick={() => {
                              Swal.fire(DElALERT).then((result) => {
                                if (result.isConfirmed) {
                                  if (result.value) {
                                    Del(value.code);
                                  }
                                }
                              });
                            }}
                          >
                            <SVGDelete color="none" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ShowData;
