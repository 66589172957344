import React, { Fragment, useState, useEffect } from 'react';
import PositionDetail from './PositionDetail';
import { useHistory, useLocation } from 'react-router';
import { GetCareerDetail } from '../../../services/Service.Career';

export default function JobDetail() {
  const history = useHistory();
  const param = useLocation();
  const [data, setData] = useState({
    code: '',
    positionName: '',
    openingDate: '',
    workLocation: '',
    responsibility: '',
    feature: '',
  });
  useEffect(() => {
    if (param.state !== undefined) {
      showData(param.state.value.code);
    } else {
      history.push('Career');
    }
  }, [history, param.state]);

  async function showData(code) {
    const res = await GetCareerDetail(code);
    if (res !== undefined) setData(res);
  }

  return (
    <Fragment>
      <div className="container mx-auto p-4">
        <div className="mr-3">
          <div className="flex justify-end mt-8 container mx-auto">
            <span
              className="text-black hover:text-blue-800 cursor-pointer text-opacity-50"
              onClick={() => {
                history.push('Home');
              }}
            >
              หน้าแรก/
            </span>
            <span
              className="text-black hover:text-blue-800 cursor-pointer text-opacity-50"
              onClick={() => {
                history.push('Career');
              }}
            >
              ตำแหน่งที่เปิดรับ/
            </span>
            <span className="text-blue-700">รายละเอียด</span>
          </div>
        </div>
        {/** for display content */}
        <PositionDetail data={data} />
      </div>
    </Fragment>
  );
}
