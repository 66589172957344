import React, { Component, Fragment } from "react";
import ShowData from "./ShowData";
import { GetContact } from "../../../services/Service.Contact";
import CPagination from "../../../components/CPagination";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { Formik, Form } from "formik";
import Icon from "../../../assets/icons/icons";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagin: {
        currentPage: 1,
        totalPage: 1,
        totalRow: 0,
      },
      loading: false,
      notData: false,
    };
  }

  componentDidMount() {
    this.showData(10, this.state.pagin.currentPage, "", "");
  }

  async showData(pageSize, currentPage, category, date) {
    this.setState({ loading: true, notData: false });
    const res = await GetContact(pageSize, currentPage, category, date);
    if (res !== undefined) {
      if (res.data.length > 0) {
        this.setState({
          data: res.data,
          pagin: {
            currentPage: res.pagin.currentPage,
            pageSize: res.pagin.pageSize,
            totalPage: res.pagin.totalPage,
            totalRow: res.pagin.totalRow,
          },
        });
      } else {
        this.setState({ notData: true });
      }
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <Fragment>
        <section id="contact">
          <div className="mt-10 mb-10">
            <div className="container px-5 mx-auto">
              <div className="mb-5 mr-3">
                <div className="container flex justify-end mx-auto mt-8">
                  <span
                    className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                    onClick={() => {
                      this.props.history.push("MGHome");
                    }}
                  >
                    หน้าแรก/
                  </span>
                  <span className="text-blue-700">ข้อความถึงเรา</span>
                </div>
              </div>
              <div className="mt-5 mb-10 ml-5 mr-5 text-center">
                <label className="text-3xl text-blue-800">ข้อความถึงเรา</label>
              </div>
              <Formik
                // validationSchema={Schema}
                initialValues={{
                  category: "",
                  date: "",
                }}
                enableReinitialize={true}
                onSubmit={(value) => {
                  this.showData(
                    10,
                    this.state.pagin.currentPage,
                    value.category,
                    value.date
                  );
                }}
              >
                {({ errors, touched, handleBlur, setFieldValue, values }) => (
                  <Form>
                    <div className="container mx-auto">
                      <div className="flex justify-start mt-7">
                        <div className="flex lg:w-2/3 w-full sm:flex-row flex-col px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 ">
                          <div className="relative flex-grow w-full">
                            <Select
                              value={values.category}
                              name="category"
                              type="text"
                              text="-- ประเภทการติดต่อ --"
                              data={[
                                { code: "1", name: "สั่งซื้อโปรแกรม" },
                                { code: "2", name: "สอบถามรายละเอียดโปรแกรม" },
                                { code: "3", name: "ขอทดลองใช้โปรแกรม (Demo)" },
                                {
                                  code: "4",
                                  name: "แจ้งปัญหา/อุปสรรคในการใช้งานโปรแกรม",
                                },
                                {
                                  code: "5",
                                  name: "เสนอแนะ/แสดงความคิดเห็นเกี่ยวกับโปรแกรม",
                                },
                                { code: "6", name: "อื่นๆ" },
                              ]}
                              onChange={(value) => {
                                setFieldValue("category", value);
                              }}
                            />
                          </div>
                          <div className="relative flex-grow w-full">
                            <Input
                              value={values.date}
                              name="date"
                              type="date"
                              onChange={(value) => {
                                setFieldValue("date", value);
                              }}
                            />
                          </div>
                          <div className="flex">
                            <button
                              type="submit"
                              className="mr-1 text-white bg-blue-700 border-0 h-10 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded text-lg"
                            >
                              ค้นหา
                            </button>
                            <button
                              type="reset"
                              className="ml-1 text-white bg-gray-400 border-0 h-10 py-2 px-8 focus:outline-none hover:bg-gray-500 rounded text-lg"
                              onClick={this.clear}
                            >
                              ล้าง
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={!this.state.notData ? "" : "hidden"}>
                        <ShowData
                          data={this.state.data}
                          pagin={this.state.pagin}
                        />
                      </div>
                      <div
                        className={
                          this.state.notData
                            ? "text-center mt-40 mb-32 text-red-500"
                            : "hidden"
                        }
                      >
                        ยังไม่มีข้อความถึงเรา
                      </div>
                      <CPagination
                        totalPage={this.state.pagin.totalPage}
                        currentPage={this.state.pagin.currentPage}
                        onChange={(value) => {
                          this.showData(10, value, "", "");
                        }}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Contact;
