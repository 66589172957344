var initialState = {
  // member_code: localStorage.getItem('member_code'),
  // email: localStorage.getItem('email'),
  // name: localStorage.getItem('name'),
  // role: localStorage.getItem('role'),
};

export default function Authentication(state = initialState, action) {
  switch (action.type) {
    case 'AUTHEN':
      // localStorage.setItem('member_code', action.member_code);
      // localStorage.setItem('email', action.email);
      // localStorage.setItem('name', action.name);
      // localStorage.setItem('role', action.role);

      return {
        ...state,
        // member_code: localStorage.getItem('member_code'),
        // email: localStorage.getItem('email'),
        // name: localStorage.getItem('name'),
        // role: localStorage.getItem('role'),
      };
    case 'UAUTHEN':
      // localStorage.removeItem('member_code');
      // localStorage.removeItem('email');
      // localStorage.removeItem('name');
      // localStorage.removeItem('role');
      return {
        ...state,
        // member_code: localStorage.getItem('member_code'),
        // email: localStorage.getItem('email'),
        // name: localStorage.getItem('name'),
        // role: localStorage.getItem('role'),
      };
    case 'USERINFO':
      return {
        ...state,
        // member_code: localStorage.getItem('member_code'),
        // email: localStorage.getItem('email'),
        // name: localStorage.getItem('name'),
        // role: localStorage.getItem('role'),
      };
    default:
      return {
        ...state,
        // member_code: localStorage.getItem('member_code'),
        // email: localStorage.getItem('email'),
        // name: localStorage.getItem('name'),
        // role: localStorage.getItem('role'),
      };
  }
}
