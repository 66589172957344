import React, { Fragment } from "react";
import { WaveAbout } from "../../../assets/images/background/WaveAbout";

function Vision() {
  return (
    <Fragment>
      <div
        className="md:pb-96 pb-80 bg-cover mb-10"
        style={{
          paddingBottom: "20rem",
          backgroundImage: WaveAbout,
        }}
      >
        <div className="container mx-auto flex px-5 md:flex-row flex-col items-center h-full">
          <div className="lg:max-w-lg lg:w-full mb-auto mt-10 md:mb-0 sm:mr-auto sm:ml-auto">
            <img
              className="md:h-96 md:w-96 object-cover object-center rounded-full h-40 w-40"
              alt="hero"
              src="https://dummyimage.com/400x400"
            />
          </div>
          <div className="mt-10 lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
              วิสัยทัศน์และพันธกิจ
            </h1>
            <h3>วิสัยทัศน์ (Vision) </h3>
            <p className="mb-8 leading-relaxed text-white">
              ผลิตซอฟต์แวร์ที่มีคุณภาพดี การให้บริการที่ประทับใจ
              พร้อมทั้งถ่ายทอดความรู้และนวัตกรรมใหม่ๆ สู่สังคม
            </p>
            <h3>พันธกิจ (Mission) </h3>
            <p className="leading-relaxed text-white">
              1. ผลิตซอฟต์แวร์ที่ดีมีคุณภาพ ตรงต่อเวลา
            </p>
            <p className="mb-8 leading-relaxed text-white">
              2. ให้บริการฝึกอบรมและที่ปรึกษาทางด้านเทคโนโลยีคอมพิวเตอร์
              โดยผู้เชี่ยวชาญทางด้านเทคโนโลยี
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Vision;
