import del_Icon from "../assets/images/about/del.gif";

export const DElALERT = {
  text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
  imageUrl: del_Icon,
  imageWidth: 80,
  imageHeight: 80,
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "ตกลง",
  cancelButtonText: "ยกเลิก",
};
