import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getIn, ErrorMessage } from 'formik';

function Select({ name, onChange, id, value, text, data, errors, touched }) {
  return (
    <Fragment>
      <select
        id={id}
        value={value}
        name={name}
        className={
          'w-full h-10 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ' +
          (getIn(touched, name)
            ? getIn(errors, name)
              ? 'bg-white rounded border border-red-500 text-base outline-none text-gray-700'
              : 'bg-white rounded border border-green-500 text-base outline-none text-gray-700'
            : 'bg-white rounded border border-gray-400 focus:border-blue-800 text-base outline-none text-gray-700')
        }
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        <option value="">{text}</option>
        {data.map((value, index) => (
          <option value={value.code} key={index}>
            {value.name}
          </option>
        ))}
      </select>
      <div className="text-red-500 text-sm">
        <ErrorMessage component="div" name={name} className="invalid-feedback" />
      </div>
    </Fragment>
  );
}

Select.defaultProps = {
  name: '',
  id: '',
  value: '',
  text: '',
  data: [{ code: '1', name: 'aaa' }],
};

Select.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  text: PropTypes.string,
  data: PropTypes.array,
};

export default Select;
