import React, { Fragment } from 'react';
// import CCard from '../../../components/CCard';
import ASSIGNTASK from '../../../assets/images/home/ASSIGNTASK2.png';
import VacationLeave from '../../../assets/images/home/vacationLeave.png';
import Jak from '../../../assets/images/apprentice/jak.png';
import Taradol from '../../../assets/images/apprentice/taradol.png';

function InternShip() {
  var ourWork = [
    { jobName: 'ระบบมอบหมายงาน', logo: ASSIGNTASK, by: 'นาย ธาราดล สอนหุ่น', university: 'มหาลัยราชภัฏกาญจนบุรี', profile: Taradol },
    { jobName: 'ระบบการลางาน', logo: VacationLeave, by: 'นาย ณัฐพล คนตรง', university: 'มหาลัยราชภัฏกาญจนบุรี', profile: Jak },
  ];
  return (
    <Fragment>
      <div className="mb-10">
        <div className="container px-5 mx-auto">
          <div className="mb-10">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-black mb-2">ผลงานน้องนักศึกษาฝึกงาน</h1>
            <div className="h-1 w-10 bg-blue-800 rounded mb-4"></div>
          </div>
          <div className="flex flex-wrap -m-4">
            {ourWork.map((value, index) => (
              // <CCard logo={value.logo} text={value.jobName} key={'InternShip' + index} />
              <div className="w-full p-4 lg:w-1/4" key={'InternShip' + index}>
                <div className="grid min-h-full p-3 border-t-4 border-blue-800 shadow-md justify-items-center ">
                  <img src={value.logo} alt={value.logo} className="w-20 h-20 mb-5 lg:w-24 lg:h-24" width={80} height={80} />
                  <h2 className="mb-3 text-lg font-medium text-center text-gray-900 title-font">{value.jobName}</h2>
                  <div className="w-full flex mt-2">
                    <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-1">
                      <img src={value.profile} className="w-full h-full rounded-full" alt={value.profile} />
                    </div>
                    <div className="flex-grow pl-6 pt-1">
                      <h2 className="text-gray-600 text-sm">{value.by}</h2>
                      <p className="text-gray-600 text-sm">{value.university}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default InternShip;
