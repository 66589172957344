import React, { Component } from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { GlobeAltIcon } from "@heroicons/react/solid";
import HCLOGO from "../../assets/images/logo/HCLOGO.png";

class Header extends Component {
  render() {
    var pathname = this.props.history.location.pathname;
    const navigation = [
      {
        name: "หน้าแรก",
        href: "Home",
        current: pathname === "/Home" ? true : false,
      },
      {
        name: "ผลิตภัณฑ์และการบริการ",
        href: "Product",
        current: pathname === "/Product" ? true : false,
      },
      {
        name: "ลูกค้าของเรา",
        href: "Customers",
        current: pathname === "/Customers" ? true : false,
      },
      {
        name: "ร่วมงานกับฮักโค้ด",
        href: "Career",
        current: pathname === "/Career" ? true : false,
      },
      {
        name: "ข่าวสาร/กิจกรรม",
        href: "News",
        current: pathname === "/News" ? true : false,
      },
      /* {
        name: "ฮักโค้ดอะคาเดมี่",
        href: "Academy",
        current: pathname === "/Academy" ? true : false,
      },
      {
        name: 'เกี่ยวกับเรา',
        href: 'About',
        current: pathname === '/About' ? true : false,
      },*/
      {
        name: "ติดต่อเรา",
        href: "Contact",
        current: pathname === "/Contact" ? true : false,
      },
    ];

    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }

    var changeLanguage = (
      <Menu as="div" className="relative ml-4">
        <div>
          <Menu.Button aria-label="Language">
            <GlobeAltIcon className="text-gray-900 text-opacity-50 hover:text-blue-800 w-9" />
            {/* <img src={Language} alt={Language} className="w-9 h-9" /> */}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl dark:bg-gray-800">
            <a
              href="#/"
              className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-blue-700 hover:text-white dark:hover:text-white"
            >
              ภาษาไทย
            </a>
            <a
              href="#/"
              className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-blue-700 hover:text-white dark:hover:text-white"
            >
              English
            </a>
            <a
              href="#/"
              className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-blue-700 hover:text-white dark:hover:text-white"
            >
              Tiếng Việt
            </a>
            <a
              href="#/"
              className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-blue-700 hover:text-white dark:hover:text-white"
            >
              ພາສາລາວ
            </a>
          </Menu.Items>
        </Transition>
      </Menu>
    );
    return (
      <div className="sticky top-0 z-20">
        <Disclosure
          as="nav"
          className="border-b-4 border-blue-1000 rounded-bl-3xl rounded-br-3xl bg-gray-50"
        >
          {({ open }) => (
            <>
              <div className="px-2 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-20">
                  <div className="absolute inset-y-0 left-0 flex items-center xl:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button
                      aria-label="Open main menu"
                      className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex items-center justify-center flex-1 xl:items-stretch xl:justify-start">
                    {/* ----------------------logo----------------------- */}
                    <div className="flex items-center flex-shrink-0">
                      <div
                        className="text-blue-800 cursor-pointer"
                        onClick={(e) => {
                          this.props.history.push("Home");
                        }}
                      >
                        <div className="w-full">
                          <div className="flex items-center h-full rounded-lg">
                            <img
                              alt="team"
                              className="flex-shrink-0 object-center mr-4 w-14 h-16object-cover"
                              src={HCLOGO}
                              height={10}
                              width={10}
                            />
                            <div className="flex-grow">
                              <div className="font-black">
                                บริษัท ฮักโค้ด จำกัด
                              </div>
                              <div className="font-black">HUGCODE CO.,LTD.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center xl:hidden">
                    {changeLanguage}
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    {/* ----------------menu--------------------- */}
                    <div className="hidden xl:block sm:ml-6">
                      <div className="flex space-x-1">
                        {navigation.map((item) => (
                          <div
                            key={item.name}
                            className={classNames(
                              item.current
                                ? "text-blue-800 cursor-pointer"
                                : "text-gray-900 hover:text-blue-800 cursor-pointer",
                              "px-3 py-2 rounded-md text-sm font-medium mt-2"
                            )}
                            aria-current={item.current ? "page" : undefined}
                            onClick={(e) => {
                              this.props.history.push(item.href);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {item.name}
                            <div
                              className={classNames(
                                item.current
                                  ? "h-0.5 w-4 bg-blue-800 rounded mb-4"
                                  : "h-0.5 w-4 bg-gray-50 rounded mb-4"
                              )}
                            ></div>
                          </div>
                        ))}
                        <div className="mt-2 border-l-2 border-fuchsia-600">
                          {changeLanguage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="xl:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <b
                      key={item.name}
                      className={classNames(
                        item.current
                          ? "bg-blue-700 text-white cursor-pointer"
                          : "text-gray-900 hover:bg-blue-700 hover:text-white cursor-pointer",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                      onClick={(e) => {
                        this.props.history.push(item.href);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {item.name}
                    </b>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  }
}

export default Header;
