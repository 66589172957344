import React, { useState, Fragment } from "react";
import SVGView from "../../../svgs/SVGView";
// import SVGEdit from '../../../svgs/SVGEdit';
import SVGDelete from "../../../svgs/SVGDelete";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

function ShowData({ data, pagin }) {
  const [open, setOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState({});

  function convertHtml(data) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: data,
        }}
      ></div>
    );
  }

  return (
    <Fragment>
      <div className="overflow-x-auto">
        <div className="min-w-screen flex items-center justify-center overflow-hidden">
          <div className="w-full">
            <div className="bg-white rounded my-6 overflow-auto">
              <table className="min-w-max w-full table-auto">
                <thead>
                  <tr className="bg-blue-800 text-white percase text-md leading-normal">
                    <th className="px-6 py-2 text-left" style={{ width: "5%" }}>
                      #
                    </th>
                    <th className="text-left" style={{ width: "10%" }}>
                      วันที่ติดต่อ
                    </th>
                    <th className="text-left" style={{ width: "20%" }}>
                      ชื่อ-นามสกุล
                    </th>
                    <th className="text-left" style={{ width: "20%" }}>
                      ติดต่อ
                    </th>
                    <th className="text-center" style={{ width: "20%" }}>
                      ประเภทการติดต่อ
                    </th>
                    <th className="text-center" style={{ width: "10%" }}>
                      จัดการ
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-md font-light">
                  {data.map((value, index) => (
                    <tr
                      className={
                        "border-b border-gray-200 " +
                        (index % 2 !== 0 ? "bg-gray-100" : "")
                      }
                      key={value.code}
                    >
                      <td className="px-6 py-2 text-center">
                        <div className="flex items-center">
                          {(pagin.currentPage - 1) * 10 + (index + 1)}
                        </div>
                      </td>
                      <td className="text-left">
                        <div className="flex items-center">
                          <span>{value.createDateTH}</span>
                        </div>
                      </td>
                      <td className="text-left">
                        <div className="flex items-center">
                          <span>{value.fullname}</span>
                        </div>
                      </td>
                      <td className="text-left">
                        <div>อีเมล : {value.email}</div>
                        <div>เบอร์โทร : {value.mobilePhone}</div>
                      </td>
                      <td className="text-left">
                        <div>{value.contactTypeName}</div>
                      </td>
                      <td className="text-center">
                        <div className="flex item-center justify-center">
                          <div
                            className="w-4 mr-2 transform hover:text-blue-700 hover:scale-110 cursor-pointer"
                            onClick={() => {
                              setOpen(true);
                              setDataDetail(value);
                            }}
                          >
                            <SVGView color="none" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {convertHtml(dataDetail.detail)}
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    ปิด
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}

export default ShowData;
