import React, { Fragment } from 'react';
import { getIn, ErrorMessage } from 'formik';

function Input({ type, name, onChange, id, value, errors, touched }) {
  return (
    <Fragment>
      <input
        value={value}
        type={type}
        name={name}
        className={
          'w-full h-10 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ' +
          (getIn(touched, name)
            ? getIn(errors, name)
              ? 'bg-white rounded border border-red-500 text-base outline-none text-gray-700'
              : 'bg-white rounded border border-green-500 text-base outline-none text-gray-700'
            : 'bg-white rounded border border-gray-400 focus:border-blue-800 text-base outline-none text-gray-700')
        }
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <div className="text-red-500 text-sm">
        <ErrorMessage component="div" name={name} className="invalid-feedback" />
      </div>
    </Fragment>
  );
}

export default Input;
