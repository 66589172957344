import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Carousel } from "react-responsive-carousel";
import SVGCalendarV1 from "../svgs/SVGCalendarV1";
import { nanoid } from "nanoid";

function CNews({ data }) {
  const history = useHistory();
  const [step, setStep] = useState(0);
  
  return (
    <Fragment>
      <div className="container mx-auto">
        <div className="flex flex-wrap -m-4">
          {data.map((value, index) => (
            <div className="p-4 xl:w-1/3 md:w-1/2" key={"New" + index}>
              <div className="relative min-h-full p-6 bg-white rounded-lg shadow-md cursor-pointer hover:text-blue-700">
                <Carousel
                  selectedItem={step}
                  showThumbs={false}
                  autoPlay={true}
                  infiniteLoop={true}
                  onChange={(e) => {
                    setStep(e);
                  }}
                  className="-mt-5"
                >
                  {value.images.map((x) => (
                    <img
                      className="object-cover object-center w-full mb-6 rounded h-72"
                      src={x.imgNewsUrl}
                      alt={x.imags}
                      height="10%"
                      width="10%"
                      key={nanoid()}
                    />
                  ))}
                </Carousel>
                <div
                  onClick={(e) => {
                    history.push("NewsDetails", { value: value });
                    window.scrollTo(0, 0);
                  }}
                >
                  <p className="mb-5 text-base font-black leading-relaxed ">{value.headlines}</p>
                  <p className="mb-10 text-base leading-relaxed text-black truncate">
                    {value.newsDetails.replace(/(<([^>]+)>)/gi, "")}
                  </p>
                  <div className="absolute bottom-0 mt-5 mb-5 right-2">
                    <div className="flex">
                      <SVGCalendarV1 color="#0B84E9" />
                      <span className="text-sm text-black">{value.createDate}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

CNews.defaultProps = {
  data: [],
};

CNews.propTypes = {
  logo: PropTypes.string,
  text: PropTypes.string,
};

export default CNews;
