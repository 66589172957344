import React, { Fragment } from "react";
import PropTypes from "prop-types";

function CCard({ logo, text }) {
  return (
    <Fragment>
      <div className="w-full p-4 lg:w-1/4 ">
        <div className="grid min-h-full p-5 border-t-4 border-blue-800 shadow-md justify-items-center ">
          <img
            src={logo}
            alt={logo}
            className="w-20 h-20 mb-5 lg:w-24 lg:h-24"
            width={80}
            height={80}
          />
          <h2 className="mb-3 text-lg font-medium text-center text-gray-900 title-font">
            {text}
          </h2>
        </div>
      </div>
    </Fragment>
  );
}

CCard.defaultProps = {
  logo: "https://dummyimage.com/400x400",
  text: "",
};

CCard.propTypes = {
  logo: PropTypes.string,
  text: PropTypes.string,
};

export default CCard;
