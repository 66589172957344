import React, { Fragment } from "react";
import CCardWithContent from "../../../components/CCardWithContent";
import webAppIcon from "../../../assets/images/services/webapp.jpg";
import mobileAppIcon from "../../../assets/images/services/mobileapp.jpg";
import packswIcon from "../../../assets/images/services/packsw.jpg";
import trainingIcon from "../../../assets/images/services/training.jpg";

export default function Services() {
  var ourServices = [
    {
      svName: "เว็บแอปพลิเคชัน",
      logo: webAppIcon,
      desc: "บริการออกแบบและพัฒนาเว็บแอปพลิเคชั่น เว็บไซต์ ด้วยเทคโนโลยีที่ทันสมัย รองรับการใช้งานบนอุปกรณ์สมาร์ทโฟน (Responsive Design) มีระบบบริหารจัดการข้อมูลหลังร้าน (Back-End) ที่ง่ายต่อการใช้งาน",
    },
    {
      svName: "โมบายแอปพลิเคชัน",
      logo: mobileAppIcon,
      desc: "บริการออกแบบและพัฒนาโมบายแอปพลิเคชั่นในประเภท Native Application และ Hybrid Application เช่น ระบบสแกนบาร์โค้ดส่งพัสดุ ระบบตรวจสอบสต๊อกสินค้า ระบบลงเวลาเข้างาน เป็นต้น อีกทั้งยังจำหน่ายเทมเพลต (Template) สำหรับให้นักพัฒนานำไปใช้งาน",
    },
    {
      svName: "โปรแกรมสำเร็จรูป",
      logo: packswIcon,
      desc: "จำหน่ายโปรแกรมสำเร็จรูป ได้แก่ ระบบขายสินค้าหน้าร้าน ระบบคลินิค เป็นต้น",
    },
    {
      svName: "บริการฝึกอบรม",
      logo: trainingIcon,
      desc: "บริการฝึกอบรมหลักสูตรต่างๆ ได้แก่ การพัฒนาโมบายแอพพลิเคชั่นด้วย Flutter การพัฒนาโมบายแอพพลิเคชั่นด้วย Ionic Framework การพัฒนาโมบายแอพพลิเคชั่นด้วย React Native การพัฒนาเว็บแอพพลิเคชั่นด้วย ReactJS การพัฒนาเว็บแอพพลิเคชั่นด้วยภาษา PHP การเขียนเว็บเอพีไอด้วย C#.NET ",
    },
  ];

  return (
    <Fragment>
      <div className="mb-10">
        <div className="container px-5 mx-auto">
          <div className="mb-10">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-black mb-2">
              บริการของเรา
            </h1>
            <div className="h-1 w-10 bg-blue-800 rounded mb-4"></div>
          </div>
          <div className="flex flex-wrap -m-4">
            {ourServices.map((value, index) => (
              <CCardWithContent
                img={value.logo}
                title={value.svName}
                key={"service" + index}
                desc={value.desc}
              />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
