import React, { Component, Fragment } from "react";
// import Videocover from '../../../assets/images/coverPage/videocover.mp4';
// import { WaveHome } from '../../../assets/images/background/WaveHome';
import ImgCarousel from "./ImgCarousel";
import Services from "../services/Services";
import OurWork from "./OurWork";
import Train from "./Train";
import InternShip from "./InternShip";
import News from "./News";
import ShowCustomers from "../customers/ShowCustomers";
// import AI1 from '../../../assets/images/home/AI1.png';

class Home extends Component {
  render() {
    return (
      <Fragment>
        <ImgCarousel />
        {/*
          
         <video
          autoPlay
          muted
          loop
          style={{ height: '100%', width: '100%', objectFit: 'cover' }} //object-fit:cover
        >
          <source src={Videocover} type="video/mp4" />
        </video> 
        <div
          className="pb-20 mb-16 -mt-5 bg-cover md:pb-20 bg-gradient-to-t from-blue-800 to-blue-700"
           style={{
             paddingBottom: "8rem",
             // backgroundImage: WaveHome,
             backgroundColor:"#0960BD"
           }}
        >
          <div className="container flex flex-col items-center h-full px-5 mx-auto lg:flex-row">
            <div className="flex flex-col items-center mt-32 text-center lg:flex-grow lg:w-full lg:pl-10 md:pl-16">
              <h1 className="mb-4 text-3xl font-medium text-white sm:text-4xl">
                เพราะเราเชื่อว่า....
              </h1>
              <h1 className="mb-4 text-3xl font-medium text-white sm:text-4xl">
                ซอฟต์แวร์ที่มีคุณภาพ จะช่วยให้ชีวิตการทำงานของท่านสบายขึ้น
              </h1>
              <p className="mb-8 leading-relaxed text-white">
                เรามีทีมงานที่มากประสบการณ์ ส่งงานตรงเวลา
                พัฒนาซอฟต์แวร์ด้วยเทคโนโลยีที่ทันสมัย
              </p>
            </div>
             <div className="mt-32 mb-auto lg:max-w-lg lg:w-full lg:pl-32 md:mb-0 sm:mr-auto sm:ml-auto">
               <img
                className="object-cover object-center w-50 h-50 md:h-96 md:w-96"
                alt="hero"
                src={AI1}
                height={150}
                width={100}
              />
            </div> 
          </div>
        </div>
         */}
        <div className="mt-10 mb-20 ">
          <section id="OurServices">
            <Services />
          </section>
          <section>
            <div className="mb-10">
              <div className="container px-5 mx-auto">
                <div className="mb-10">
                  <h1 className="sm:text-3xl text-2xl font-medium title-font text-black mb-2">
                    ลูกค้าของเรา
                  </h1>
                  <div className="h-1 w-10 bg-blue-800 rounded mb-4"></div>
                </div>
                <ShowCustomers />
              </div>
            </div>
          </section>
          {/**
          <section id="OurWork">
            <OurWork />
          </section>
           */}
          <section id="Train">
            <Train />
          </section>
          <section id="InternShip">
            <InternShip />
          </section>
          {/* <section id="New">
          <News />
        </section> */}
        </div>
      </Fragment>
    );
  }
}

export default Home;
