import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function CPagination({ totalPage, currentPage, onChange }) {
  return (
    <Fragment>
      <div className="flex flex-col items-center my-10">
        <div className="flex text-gray-700">
          <div
            className={'h-9 w-9 mr-1 flex justify-center items-center rounded-full bg-gray-200 ' + (currentPage === 1 ? '' : 'hover:bg-blue-800 hover:text-white cursor-pointer')}
            onClick={() => {
              if (currentPage > 1) onChange(currentPage - 1);
            }}
          >
            {'<'}
          </div>
          <div className="flex h-9 font-medium rounded-full ">
            {[...Array(parseInt(totalPage))].map((v, i) => (
              <div
                key={'pagin' + i}
                className={
                  'h-9 w-9 ml-1 mr-1 md:flex justify-center items-center hidden bg-gray-200 hover:bg-blue-800 hover:text-white cursor-pointer leading-5 transition duration-150 ease-in rounded-full' +
                  (currentPage === i + 1 ? ' bg-blue-700 text-white' : '')
                }
                onClick={() => {
                  onChange(i + 1);
                }}
              >
                {i + 1}
              </div>
            ))}
          </div>
          <div
            className={'h-9 w-9 ml-1 flex justify-center items-center rounded-full bg-gray-200' + (currentPage === totalPage ? '' : 'hover:bg-blue-800 hover:text-white cursor-pointer')}
            onClick={() => {
              if (currentPage < totalPage) onChange(currentPage + 1);
            }}
          >
            {'>'}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

CPagination.defaultProps = {
  totalPage: 1,
  currentPage: 1,
};

CPagination.propTypes = {
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
};

export default CPagination;
