import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Routes from './routes/Routes';
import SwitchRoute from './layouts/SwitchLayout';
import { nanoid } from 'nanoid';
import './styles/tailwind.css'


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {Routes.map((prop) => {
            return <SwitchRoute exact path={prop.path} component={prop.component} layout={prop.layout} key={nanoid()} {...this.props} />;
          })}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
