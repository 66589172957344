import Instance from "../helper/Axios";

export async function GetProduct(
  pageSize,
  currentPage,
  category,
  sortBy,
  search
) {
  try {
    const response = await Instance.get(
      "Product/GetProduct?pageSize=" +
        pageSize +
        "&currentPage=" +
        currentPage +
        "&category=" +
        category +
        "&sortBy=" +
        sortBy +
        "&search=" +
        search
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function GetCategory() {
  try {
    const response = await Instance.get("ProductCategory/GetCategory");
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
