import React, { Component, Fragment } from 'react';
import { Formik, Form /* ErrorMessage, getIn, FieldArray */ } from 'formik';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Icon from '../../../assets/icons/icons';
import ShowProduct from './ShowProduct';
import { GetProduct, GetCategory } from '../../../services/Service.Product';
import Loading from './Loading';
import CPagination from '../../../components/CPagination';
import { Menu, Transition } from '@headlessui/react';
import Service from '../services/Services';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      categoryData: [],
      pagin: {
        currentPage: 1,
        totalPage: 1,
        totalRow: 0,
      },
      loading: false,
      notData: false,
      pageSize: 6,
      currentPage: 1,
      category: '',
      sortBy: '',
      search: '',
    };
    this.service = React.createRef();
  }

  componentDidMount() {
    this.loadCategory();
    this.showData(6, this.state.currentPage, this.state.category, this.state.sortBy, this.state.search);
  }

  async showData(pageSize, currentPage, category, sortBy, search) {
    this.setState({ loading: true, notData: false });
    const res = await GetProduct(pageSize, currentPage, category, sortBy, search);
    if (res !== undefined) {
      if (res.data.length > 0) {
        this.setState({
          data: res.data,
          pagin: {
            currentPage: res.pagin.currentPage,
            pageSize: res.pagin.pageSize,
            totalPage: res.pagin.totalPage,
            totalRow: res.pagin.totalRow,
          },
        });
      } else {
        this.setState({ notData: true });
      }
      this.setState({ loading: false });
    }
  }

  async loadCategory() {
    const res = await GetCategory();
    if (res !== undefined) {
      if (res.length > 0) {
        this.setState({
          categoryData: res,
        });
      }
    }
  }

  clear = () => {
    this.showData(6, 1, '', '', '');
    this.setState({
      data: [],
      pagin: {
        currentPage: 1,
        pageSize: 6,
        totalPage: 1,
        totalRow: 0,
      },
      notData: false,
      category: '',
      sortBy: '',
      search: '',
    });
  };

  scroll = (section) => {
    section.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  render() {
    return (
      <Fragment>
        <div className="h-6 -mt-5 bg-blue-800" />
        <div className="h-16 text-center text-white bg-blue-800 ">
          <div className="flex justify-center pt-4">
            <div className="grid grid-cols-2 divide-x divide-white">
              <div>
                <span>
                  <Menu as="div" className="relative mr-4 ">
                    <div>
                      <Menu.Button aria-label="Language">ผลิตภัณฑ์</Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl dark:bg-gray-800">
                        <div
                          className="cursor-pointer block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-blue-700 hover:text-white dark:hover:text-white"
                          onClick={() => {
                            this.setState({ category: '' });
                            this.showData(6, this.state.currentPage, '', '', '');
                          }}
                        >
                          ทั้งหมด
                        </div>
                        {this.state.categoryData.map((value, index) => (
                          <div
                            key={'category' + index}
                            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-blue-700 hover:text-white dark:hover:text-white"
                            onClick={() => {
                              this.setState({ category: value.code });
                              this.showData(6, this.state.currentPage, value.code, '', '');
                            }}
                          >
                            {value.categoryName}
                          </div>
                        ))}
                        <div
                          className="cursor-pointer block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-blue-700 hover:text-white dark:hover:text-white"
                          onClick={() => {
                            this.setState({ category: 'sale' });
                            this.showData(6, this.state.currentPage, 'sale', '', '');
                          }}
                        >
                          สินค้าลดราคา
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </span>
              </div>
              <div>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    this.scroll(this.service);
                  }}
                >
                  บริการ
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-4 mx-auto mb-5">
          <div className="mr-3">
            <div className="container flex justify-end mx-auto mt-8">
              <span
                className="text-black text-opacity-50 cursor-pointer hover:text-blue-800"
                onClick={() => {
                  this.props.history.push('Home');
                }}
              >
                หน้าแรก/
              </span>
              <span className="text-blue-700">ผลิตภัณฑ์/บริการ</span>
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <div className="mr-5">
              <div className="font-sans block mt-4 lg:inline-block lg:mt-0 lg:ml-6 align-middle text-black hover:text-gray-700">
                <span className="relative flex cursor-pointer">
                  <Icon icon="trolley" className="flex-1 w-8 h-8 fill-current text-blue-800 hover:text-blue-700" color="#" />
                  <span className="absolute right-0 top-0 rounded-full bg-red-600 w-4 h-4 top right p-0 m-0 text-white font-mono text-sm  leading-tight text-center">0</span>
                </span>
              </div>
            </div>
          </div>
          <Formik
            initialValues={{
              order_by: '',
              search: '',
            }}
            enableReinitialize={true}
            onSubmit={(value) => {
              this.showData(6, this.state.currentPage, this.state.category, value.order_by, value.search);
              this.setState({
                data: [],
                pagin: {
                  currentPage: 1,
                  pageSize: 6,
                  totalPage: 1,
                  totalRow: 0,
                },
                notData: false,
              });
            }}
          >
            {({ errors, touched, handleBlur, setFieldValue, values }) => (
              <Form>
                <div className="flex justify-end mt-7">
                  <div className="flex lg:w-2/3 w-full sm:flex-row flex-col px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
                    <div className="relative flex-grow w-full">
                      <Select
                        value={values.order_by}
                        name="search"
                        type="text"
                        text="-- เรียงลำดับ --"
                        data={[
                          { code: '0', name: 'สินค้าล่าสุด' },
                          { code: '1', name: 'ชื่อสินค้า ก-ฮ' },
                          { code: '2', name: 'ชื่อสินค้า ฮ-ก' },
                          { code: '3', name: 'ราคาสินค้า จากน้อยไปมาก' },
                          { code: '4', name: 'ราคาสินค้า จากมากไปน้อย' },
                        ]}
                        onChange={(value) => {
                          setFieldValue('order_by', value);
                        }}
                      />
                    </div>
                    <div className="relative flex-grow w-full">
                      <Input
                        value={values.search}
                        name="search"
                        type="text"
                        onChange={(value) => {
                          setFieldValue('search', value);
                        }}
                      />
                    </div>
                    <div className="flex">
                      <button type="submit" className="mr-1 text-white bg-blue-700 border-0 h-10 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded text-lg">
                        ค้นหา
                      </button>
                      <button type="reset" className="ml-1 text-white bg-gray-400 border-0 h-10 py-2 px-8 focus:outline-none hover:bg-gray-500 rounded text-lg" onClick={this.clear}>
                        ล้าง
                      </button>
                    </div>
                  </div>
                </div>
                <div className={!this.state.notData && !this.state.loading ? 'container px-5 mx-auto mt-10' : 'hidden'}>
                  <ShowProduct data={this.state.data} />
                  <CPagination
                    totalPage={this.state.pagin.totalPage}
                    currentPage={this.state.pagin.currentPage}
                    onChange={(value) => {
                      this.setState({ currentPage: value });
                      this.showData(6, value, this.state.category, values.order_by, values.search);
                    }}
                  />
                </div>
                <div className={this.state.loading ? 'container px-5 py-10 mx-auto mt-10' : 'hidden'}>
                  <Loading />
                </div>
                <div className={this.state.notData ? 'text-center mt-32 text-red-500' : 'hidden'}>ไม่พบผลิตภัณฑ์!!</div>
              </Form>
            )}
          </Formik>
          <section ref={this.service}>
            <Service />
          </section>
        </div>
      </Fragment>
    );
  }
}

export default Product;
