import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function CCardWithContent({ img, title, desc }) {
  return (
    <Fragment>
      <div className="p-4 lg:w-1/2 md:w-full ">
        <div className="lg:w-full flex flex-wrap border min-h-full">
          <div className="xl:w-52 xl:mb-0 mb-5 w-full">
            <img src={img} className="lg:w-52 w-full h-full object-cover object-center m-auto" alt={img} />
          </div>
          <div className="xl:w-3/5 w-full lg:pl-5 lg:py-3 mt-6 lg:mt-0 p-3">
            <h2 className="font-medium title-font text-blue-800">{title}</h2>
            <h1 className="text-gray-700">{desc}</h1>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

CCardWithContent.defaultProps = {
  img: 'https://dummyimage.com/400x400',
  title: '',
  desc: '',
};

CCardWithContent.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string,
  desc: PropTypes.string,
};

export default CCardWithContent;
