import React, { Fragment } from 'react';
import CEO from '../../../assets/images/themhc/CEO.png';
import Tea from '../../../assets/images/themhc/Tea.png';
import Boom from '../../../assets/images/themhc/Boom.png';
import Nik from '../../../assets/images/themhc/Nik.png';

function Team() {
  return (
    <Fragment>
      <div className="container md:pl-20 pl-5 mb-20 mt-10">
        <h1 className="sm:text-3xl text-2xl font-medium title-font text-black mb-4">ทีมงานของเรา</h1>
      </div>
      <div className="container px-5 mx-auto mt-10">
        <div className="flex justify-center flex-wrap -m-4">
          <div className="lg:w-4/4 md:w-2/2 p-4 w-full">
            <div className="block relative h-48 rounded overflow-hidden">
              <img alt="ecommerce" className="object-cover object-center block rounded-full h-40 w-40 mr-auto ml-auto" src="https://dummyimage.com/400x400" />
            </div>
            <div className="mt-4 text-center">
              <h2 className="text-gray-900 title-font text-lg font-medium">??</h2>
              <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Chief Executive Officer (CEO)</h3>
            </div>
          </div>
          <div className="lg:w-4/4 md:w-2/2 p-4 w-full">
            <div className="block relative h-48 rounded overflow-hidden">
              <img alt="ecommerce" className="object-cover object-center block rounded-full h-40 w-40 mr-auto ml-auto" src={CEO} />
            </div>
            <div className="mt-4 text-center">
              <h2 className="text-gray-900 title-font text-lg font-medium">สะไบแพร อาจศรี</h2>
              <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Chief Executive Officer (CEO)</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-5 py-14 mx-auto ">
        <div className="flex justify-center flex-wrap -m-4">
          <div className="lg:w-1/3 md:w-1/2 p-4 w-full">
            <div className="block relative h-48 rounded overflow-hidden">
              <img alt="ecommerce" className="object-cover object-center  block rounded-full h-40 w-40 mr-auto ml-auto" src={Tea} />
            </div>
            <div className="mt-4 text-center">
              <h2 className="text-gray-900 title-font text-lg font-medium">โสฬส เจริยสินชัย</h2>
              <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Developer (Dev.)</h3>
            </div>
          </div>
          <div className="lg:w-1/3 md:w-1/2 p-4 w-full">
            <div className="block relative h-48 rounded overflow-hidden">
              <img alt="ecommerce" className="object-cover object-center  block rounded-full h-40 w-40 mr-auto ml-auto" src={Boom} />
            </div>
            <div className="mt-4 text-center">
              <h2 className="text-gray-900 title-font text-lg font-medium">จักรกฤษณ์ ดอกเข็ม</h2>
              <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Developer (Dev.)</h3>
            </div>
          </div>
          <div className="lg:w-1/3 md:w-1/2 p-4 w-full">
            <div className="block relative h-48 rounded overflow-hidden">
              <img alt="ecommerce" className="object-cover object-center  block rounded-full h-40 w-40 mr-auto ml-auto" src={Nik} />
            </div>
            <div className="mt-4 text-center">
              <h2 className="text-gray-900 title-font text-lg font-medium">สุวัจณีย์ ไม้หอม</h2>
              <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Graphic Design</h3>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Team;
