import React, { Fragment, useRef } from "react";
import FormApplyJob from "./FormApplyJob";
import SVGCalendar from "../../../svgs/SVGCalendar";
import SVGMapMaker from "../../../svgs/SVGMapMaker";

export default function PositionDetail({ data }) {
  function convertHtml(data) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: data,
        }}
      ></div>
    );
  }
  return (
    <Fragment>
      <div className="w-full flex flex-col  overflow-hidden mt-5">
        <div className="container px-5 mx-auto flex items-center md:flex-row flex-col mb-3">
          <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
            <h1 className="md:text-3xl text-2xl font-medium title-font text-gray-900 mb-3">
              ตำแหน่ง :{" "}
              <span className="font-normal text-blue-700">
                {data.positionName}
              </span>
            </h1>
            <h2 className="text-xs font-medium title-font mb-1">
              <div className="flex flex-wrap font-medium items-center mb-4 md:mb-0">
                <SVGCalendar color="#2c5282" />
                <span className="ml-3 mr-5 text-sm">
                  <label>วันที่ประกาศ : {data.openingDate}</label>
                </span>
                <SVGMapMaker color="#2c5282" />
                <span className="ml-3 text-sm">
                  <label>สถานที่ปฏิบัติงาน : {data.workLocation}</label>
                </span>
              </div>
            </h2>
          </div>
        </div>
        <div className="px-6 py-4 border-t border-gray-200">
          <div className="mt-10 mb-10">
            <div className="mb-5">
              <h1 className="sm:text-lg text-lg font-medium title-font text-black mb-2">
                ทักษะที่ทีมต้องการ
              </h1>
              <div className="h-0.5 w-5 bg-blue-800 rounded mb-4"></div>
            </div>
            <div className="ml-10">{convertHtml(data.responsibility)}</div>
          </div>
          <div className="mt-10 mb-10">
            <div className="mb-5">
              <h1 className="sm:text-lg text-lg font-medium title-font text-black mb-2">
                คุณสมบัติผู้สมัคร
              </h1>
              <div className="h-0.5 w-5 bg-blue-800 rounded mb-4"></div>
            </div>
            <div className="ml-10">{convertHtml(data.feature)}</div>
          </div>
        </div>
      </div>
      <section>
        <FormApplyJob data={data} />
      </section>
    </Fragment>
  );
}
