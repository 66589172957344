import React, { Fragment } from 'react';

function LoadingNews({ loading }) {
  return (
    <Fragment>
      <div className={loading ? 'container mx-auto' : 'hidden'}>
        <div className="flex flex-wrap -m-4">
          <div className="p-4 xl:w-1/3 md:w-1/2">
            <div className="relative min-h-full p-6 bg-white rounded-lg shadow-md hover:text-blue-700 animate-pulse pb-32">
              <div className=" bg-gray-300 h-60 w-full mb-10"></div>
              <p className="leading-relaxed mb-8 w-full h-4 animate-pulse bg-gray-300"></p>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-300"></p>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-300"></p>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-300"></p>
            </div>
          </div>
          <div className="p-4 xl:w-1/3 md:w-1/2">
            <div className="relative min-h-full p-6 bg-white rounded-lg shadow-md hover:text-blue-700 animate-pulse pb-32">
              <div className=" bg-gray-300 h-60 w-full mb-10"></div>
              <p className="leading-relaxed mb-8 w-full h-4 animate-pulse bg-gray-300"></p>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-300"></p>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-300"></p>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-300"></p>
            </div>
          </div>
          <div className="p-4 xl:w-1/3 md:w-1/2">
            <div className="relative min-h-full p-6 bg-white rounded-lg shadow-md hover:text-blue-700 animate-pulse pb-32">
              <div className=" bg-gray-300 h-60 w-full mb-10"></div>
              <p className="leading-relaxed mb-8 w-full h-4 animate-pulse bg-gray-300"></p>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-300"></p>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-300"></p>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-300"></p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LoadingNews;
